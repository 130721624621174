// react
import React, { useState, useEffect, useMemo } from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  TableFooter,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  ManageSearch as ManageSearchIcon,
  Verified as VerifiedIcon,
} from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const MpsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "timestamp", label: t("generation_datetime") },
      { id: "value", label: t("value") },
      { id: "uom", label: t("measure_unit") },
      { id: "magnitude", label: t("magnitude") },
      { id: "visibility", label: t("visibility") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            <Typography fontWeight="bold">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const MpsTable = ({ records, selectedSensors, checkVerification, handleView, page, setPage, rowsPerPage, setRowsPerPage }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedSensor, setSelectedSensor] = useState("");
  const [selectedMagnitude, setSelectedMagnitude] = useState("");

  useEffect(() => {
    if (selectedSensor && selectedMagnitude) {
      const filteredRecords = records.reduce((acc, record) => {
        if (record.sensorId === selectedSensor) {
          const magnitude = record.magnitudes.find((mag) => mag.magnitude === selectedMagnitude);
          if (magnitude) {
            acc.push(...magnitude.records);
          }
        }
        return acc;
      }, []);
      setFilteredRecords(filteredRecords.reverse());
    } else {
      setFilteredRecords([]);
    }
  }, [selectedSensor, selectedMagnitude, records]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeSensor = (event) => {
    setSelectedSensor(event.target.value);
    setSelectedMagnitude("");
  };

  const handleChangeMagnitude = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRecords.length) : 0;

  return (
    <Grid container spacing={5}>
      <Grid item container spacing={1}>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel id="Source Name">{t("source_name")}</InputLabel>
            <Select labelId={t("source_name")} id="Source Name" value={selectedSensor} onChange={handleChangeSensor} input={<OutlinedInput label={t("source_name")} />}>
              {selectedSensors.map((sensorId) => {
                const sensor = records.find((record) => record.sensorId === sensorId);
                return (
                  <MenuItem key={sensorId} value={sensorId}>
                    {sensor ? sensor.sensorName : sensorId}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {selectedSensor && (
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="Magnitude">{t("magnitude")}</InputLabel>
              <Select labelId={t("magnitude")} id="Magnitude" value={selectedMagnitude} onChange={handleChangeMagnitude} input={<OutlinedInput label={t("magnitude")} />}>
                {records
                  .find((record) => record.sensorId === selectedSensor)
                  ?.magnitudes.map((magnitude) => (
                    <MenuItem key={magnitude.magnitude} value={magnitude.magnitude}>
                      {magnitude.magnitude}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table size="small">
            <MpsTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : filteredRecords).map((record) => {
                return (
                  <TableRow key={record.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
                    <TableCell align="left">{record.data.timestamp ? convertTimestamp(record.data.timestamp) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell align="left">{record.data.value || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell align="left">{record.data.uom || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell align="left">{record.data.magnitude || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
                    <TableCell align="left" sx={{ color: "gray" }}>
                      {record.public ? t("public") : t("private")}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={t("details")} placement="top">
                        <IconButton color="primary" onClick={() => handleView(record)}>
                          <ManageSearchIcon />
                        </IconButton>
                      </Tooltip>
                      {record.id.startsWith("UNCERTIFIED_") ? null : (
                        <Tooltip title={t("verify")} placement="top">
                          <IconButton color="success" onClick={() => checkVerification(record)}>
                            <VerifiedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={6}
                    count={filteredRecords.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default MpsTable;
