// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

// A ---------------------------------------------------------------------- M

const AttributeDetailsDialog = ({ open, handleClose, content }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 0.5,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeDetailsDialog;
