// firebase
import { db } from "config/firebase";
import { collection, setDoc, doc } from "firebase/firestore";

// UserOperations
import addSignature from "UserOperations/addSignature";

// A ---------------------------------------------------------------------- M

const sendDataStreamCDNFile = async (userID, dataBody, timestamp, databoxID) => {
  try {
    // Set the new record
    const recordData = {
      creator_uuid: userID,
      data: dataBody.uploadedFiles,
      tdr: databoxID,
      txid: dataBody.uploadID,
      type: "CDNFile",
      version: 1,
      timestamp,
      uploadID: dataBody.uploadID,
      metadata: dataBody.uploadProperties,
      ...dataBody.uploadProperties,
      AIC_ID: null,
    };

    const recordRef = doc(db, "recordsdata", dataBody.uploadID);
    await setDoc(recordRef, recordData);

    console.log("Record data saved:", recordData);

    // Add to signatures subcollection in tagsdata
    const tagRef = doc(db, "tagsdata", databoxID);
    const recordsRef = collection(tagRef, "signatures");

    // Set the new record link to the "signatures" subcollection
    const signatureData = {
      creator_uuid: userID,
      txid: dataBody.uploadID,
      type: "CDNFile",
      public: false,
      timestamp,
      uploadID: dataBody.uploadID,
      metadata: dataBody.uploadProperties,
      ...dataBody.uploadProperties,
      db_id: 1,
      AIC_ID: null,
    };

    console.log(signatureData);

    await setDoc(doc(recordsRef, dataBody.uploadID), signatureData);

    // Add the signature to "mysigs" subcollection of "userdata" collection
    await addSignature(userID, dataBody.uploadID, timestamp, "CDNFile", databoxID);

    // Update the "uploadStatuses" collection with the document id "uploadID"
    const uploadStatusRef = doc(db, "uploadStatuses", dataBody.uploadID);

    // Set the txid attribute in the uploadStatuses document
    const uploadStatusData = {
      txid: dataBody.uploadID,
    };

    console.log(uploadStatusData);

    await setDoc(uploadStatusRef, uploadStatusData, { merge: true });

    const result = {
      txid: dataBody.uploadID,
      success: true,
    };

    console.log(result);

    return result;
  } catch (error) {
    console.error("Error in sendDataStreamCDNFile:", error.message);

    const result = {
      success: false,
      error: error.message,
    };

    return result;
  }
};

export default sendDataStreamCDNFile;
