// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// contexts
import { UserAuth } from "context/AuthContext";

// @mui
import { useMediaQuery, Box, Dialog, IconButton, Typography, Snackbar, Alert, Tooltip } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import CDNFileTable from "./CDNFileTable";
import CDNPreservationProofDialog from "./CDNPreservationProofDialog";

// eventLogger
import logEvent from "eventLogger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileViewer = ({ tag, isTagGroupMember, isTagGroupMemberReadWrite, isTagGroupMemberReadOnly, openUploadGenDrawer }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [record, setRecord] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPreservationProofDialogOpen, setIsPreservationProofDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleOpenPreservationProofDialog = async (record) => {
    setRecord(record);
    setIsPreservationProofDialogOpen(true);
  };

  const handleClosePreservationProofDialog = () => {
    setIsPreservationProofDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileClick = async (fileUrl, fileName) => {
    const timestamp = unixTimestampInSeconds();

    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      }

      const blob = await response.blob();
      const fileType = blob.type;

      setSelectedFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "application/json") {
        const jsonText = await blob.text();
        try {
          const parsedJson = JSON.parse(jsonText);
          setTextContent(parsedJson);
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
        setSelectedFile(fileUrl);
      } else if (fileType.startsWith("image/")) {
        setSelectedFile(fileUrl);
      } else if (fileType === "video/webm" || fileType === "video/x-matroska" || fileType === "video/mp4") {
        setSelectedFile(fileUrl);
      } else {
        throw new Error("Unsupported file type");
      }

      setSnackbarMessage(t("success_opening_file"));
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      await logEvent(tag.id, "event_archive_open_file_success", timestamp, `${fileName} opened successfully.`, null, user.uid, null, "success");
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
      setSnackbarMessage(t("error_opening_file"));
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      await logEvent(tag.id, "event_archive_open_file_error", timestamp, `${fileName} opened successfully.`, null, user.uid, null, "error");
    }
  };

  const renderFileDialog = () => {
    if (!selectedFileType) {
      return null;
    }

    switch (selectedFileType) {
      case "application/pdf":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        try {
          const rows = textContent.split("\n").map((row) => row.split(","));
          return (
            <Dialog
              open={Boolean(selectedFile)}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <table>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Dialog>
          );
        } catch {
          return (
            <Dialog
              open={Boolean(selectedFile)}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography>{textContent}</Typography>
              </Box>
            </Dialog>
          );
        }

      case "application/json":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <pre>{JSON.stringify(textContent, null, 2)}</pre>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Tooltip placement="top" title={t("close")}>
              <IconButton
                color="error"
                sx={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  zIndex: 1,
                }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
            <img src={selectedFile} alt="img" />
          </Dialog>
        );

      case "video/webm":
      case "video/x-matroska":
      case "video/mp4":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 2 }}>
              <video
                src={selectedFile}
                controls
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              >
                Your browser does not support the video tag.
              </video>
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {tag && (
        <CDNFileTable
          tag={tag}
          isTagGroupMember={isTagGroupMember}
          isTagGroupMemberReadWrite={isTagGroupMemberReadWrite}
          isTagGroupMemberReadOnly={isTagGroupMemberReadOnly}
          openUploadGenDrawer={openUploadGenDrawer}
          handleFileClick={handleFileClick}
          handleOpenPreservationProofDialog={handleOpenPreservationProofDialog}
        />
      )}

      {isPreservationProofDialogOpen && record && (
        <CDNPreservationProofDialog record={record} open={isPreservationProofDialogOpen} setOpen={setIsPreservationProofDialogOpen} onClose={handleClosePreservationProofDialog} />
      )}

      {renderFileDialog()}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CDNFileViewer;
