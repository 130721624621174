// firebase
import { db } from "config/firebase";
import { doc, collection, setDoc, getDoc, updateDoc, arrayUnion, deleteDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

/**
 * Adds a user to the alarmUsers list of a tag.
 *
 * @param {string} userID - The ID of the user to add.
 * @param {string} tagID - The ID of the tag.
 */
export const addAlarmUser = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      await updateDoc(tagDocRef, {
        alarmUsers: arrayUnion(userID),
      });
      console.log(`Alarm user added to ${tagID}`);
    }
  } catch (error) {
    console.error(`Error in addAlarmUser for uid ${userID} and tag ${tagID}:`, error.message);
  }
};

/**
 * Removes a user from the alarmUsers list of a tag.
 *
 * @param {string} userID - The ID of the user to remove.
 * @param {string} tagID - The ID of the tag.
 */
export const removeAlarmUser = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      const { alarmUsers = [] } = tagDocSnapshot.data();

      if (alarmUsers.includes(userID)) {
        const updatedAlarmUsersArray = alarmUsers.filter((alarmUserUid) => alarmUserUid !== userID);
        await updateDoc(tagDocRef, { alarmUsers: updatedAlarmUsersArray });
        console.log(`Alarm user removed from ${tagID}`);
      }
    }
  } catch (error) {
    console.error(`Error in removeAlarmUser for uid ${userID} and tag ${tagID}:`, error.message);
  }
};

/**
 * Creates a new alarm trigger in the database.
 *
 * @param {string} databoxID - The ID of the databox where the trigger belongs.
 * @param {string} userID - The ID of the user creating the trigger.
 * @param {object} trigger - The trigger object containing trigger data.
 */
export const createAlarmTrigger = async (databoxID, userID, trigger) => {
  try {
    const triggerRef = doc(collection(db, "tagsdata", databoxID, "triggers"));
    await setDoc(triggerRef, {
      ...trigger,
      createdAt: unixTimestampInSeconds(),
      createdBy: userID,
    });
    console.log("Trigger created successfully!");
  } catch (error) {
    console.error("Error creating trigger:", error.message);
    throw new Error("Failed to create trigger");
  }
};

/**
 * Deletes an alarm trigger from the database.
 *
 * @param {string} databoxID - The ID of the databox where the trigger belongs.
 * @param {string} triggerID - The ID of the trigger to delete.
 */
export const deleteAlarmTrigger = async (databoxID, triggerID) => {
  try {
    const triggerRef = doc(db, "tagsdata", databoxID, "triggers", triggerID);
    await deleteDoc(triggerRef);
    console.log("Trigger deleted successfully!");
  } catch (error) {
    console.error("Error deleting trigger:", error.message);
    throw new Error("Failed to delete trigger");
  }
};
