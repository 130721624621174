// firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const checkIfUserHasAlarmOn = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (!tagDocSnapshot.exists()) {
      return false;
    }

    return tagDocSnapshot.data()?.alarmUsers?.includes(userID) || false;
  } catch (error) {
    console.error(`Error in checkIfUserHasAlarmOn for uid ${userID} and tag ${tagID}:`, error.message);
    return false;
  }
};

export default checkIfUserHasAlarmOn;
