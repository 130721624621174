// react
import React from "react";

// @mui
import { Grid, Button, Typography, Divider } from "@mui/material";

// react-i18next
import { useTranslation } from "react-i18next";

// components
import MetadataField from "./MetadataField";

// metadataSchema
import { metadataSchemaUpload } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const SoggettoForm = ({ soggetto, index, soggettoFields, handleSoggettoChange, handleRemoveSoggetto, handleClickOpen }) => {
  const { t } = useTranslation();

  const handleChange = (fieldKey, value) => {
    handleSoggettoChange(index, fieldKey, value);
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body2" fontWeight="bold">
          {`Soggetto ${index + 1}`}
        </Typography>
        <Divider />
      </Grid>
      {soggettoFields.map((fieldKey) => (
        <MetadataField key={fieldKey} fieldKey={fieldKey} metadata={metadataSchemaUpload} documentProperties={soggetto} onChange={handleChange} handleClickOpen={handleClickOpen} />
      ))}
      <Grid item container xs={12} justifyContent="flex-end">
        <Button variant="contained" color="error" onClick={() => handleRemoveSoggetto(index)}>
          {t("remove")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default SoggettoForm;
