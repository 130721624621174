// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Grid, Typography, IconButton, Divider, FormControl, InputLabel, Select, MenuItem, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

// A ---------------------------------------------------------------------- M

const ClientFormDialog = ({ open, handleClose, handleCreateClient }) => {
  const { t } = useTranslation();

  const [clientData, setClientData] = useState({
    companyName: "",
    taxCode: "",
    VATNumber: "",
    registrationNumber: "",
    email: "",
    phone: "",
    contactPerson: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
    address: {
      street: "",
      city: "",
      postalCode: "",
      country: "",
    },
    paymentTerms: "",
    bankAccountDetails: {
      bankName: "",
      accountNumber: "",
      IBAN: "",
      SWIFT: "",
    },
    taxRegime: "",
    industry: "",
    size: "",
    status: "active",
    notes: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData({ ...clientData, [name]: value });
  };

  const handleNestedInputChange = (e, group) => {
    const { name, value } = e.target;
    setClientData({
      ...clientData,
      [group]: { ...clientData[group], [name]: value },
    });
  };

  const handleSubmit = () => {
    handleCreateClient(clientData);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 0.5,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
      data-testid="client-create-modal"
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_customer")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={handleClose} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("company")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("company_name")} name="companyName" fullWidth variant="outlined" value={clientData.companyName} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("tax_code")} name="taxCode" fullWidth variant="outlined" value={clientData.taxCode} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("vat_number")} name="VATNumber" fullWidth variant="outlined" value={clientData.VATNumber} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("registration_number")} name="registrationNumber" fullWidth variant="outlined" value={clientData.registrationNumber} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("email")} name="email" fullWidth variant="outlined" value={clientData.email} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("phone")} name="phone" fullWidth variant="outlined" value={clientData.phone} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("contact_person")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("first_name")} name="firstName" fullWidth variant="outlined" value={clientData.contactPerson.firstName} onChange={(e) => handleNestedInputChange(e, "contactPerson")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("last_name")} name="lastName" fullWidth variant="outlined" value={clientData.contactPerson.lastName} onChange={(e) => handleNestedInputChange(e, "contactPerson")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("email")} name="email" fullWidth variant="outlined" value={clientData.contactPerson.email} onChange={(e) => handleNestedInputChange(e, "contactPerson")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("phone")} name="phone" fullWidth variant="outlined" value={clientData.contactPerson.phone} onChange={(e) => handleNestedInputChange(e, "contactPerson")} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("address")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("street")} name="street" fullWidth variant="outlined" value={clientData.address.street} onChange={(e) => handleNestedInputChange(e, "address")} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField label={t("city")} name="city" fullWidth variant="outlined" value={clientData.address.city} onChange={(e) => handleNestedInputChange(e, "address")} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField label={t("postal_code")} name="postalCode" fullWidth variant="outlined" value={clientData.address.postalCode} onChange={(e) => handleNestedInputChange(e, "address")} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("payment_information")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("payment_terms")} name="paymentTerms" fullWidth variant="outlined" value={clientData.paymentTerms} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("tax_regime")} name="taxRegime" fullWidth variant="outlined" value={clientData.taxRegime} onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray" fontWeight="bold">
              {t("bank_account")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("bank_name")}
              name="bankName"
              fullWidth
              variant="outlined"
              value={clientData.bankAccountDetails.bankName}
              onChange={(e) => handleNestedInputChange(e, "bankAccountDetails")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("account_number")}
              name="accountNumber"
              fullWidth
              variant="outlined"
              value={clientData.bankAccountDetails.accountNumber}
              onChange={(e) => handleNestedInputChange(e, "bankAccountDetails")}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("iban")} name="IBAN" fullWidth variant="outlined" value={clientData.bankAccountDetails.IBAN} onChange={(e) => handleNestedInputChange(e, "bankAccountDetails")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label={t("swift")} name="SWIFT" fullWidth variant="outlined" value={clientData.bankAccountDetails.SWIFT} onChange={(e) => handleNestedInputChange(e, "bankAccountDetails")} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="gray">
              {t("other_information")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t("industry")}</InputLabel>
              <Select label={t("industry")} name="industry" value={clientData.industry} onChange={handleInputChange}>
                <MenuItem value=""></MenuItem>
                <MenuItem value="technology">{t("technology")}</MenuItem>
                <MenuItem value="healthcare">{t("healthcare")}</MenuItem>
                <MenuItem value="finance">{t("finance")}</MenuItem>
                <MenuItem value="education">{t("education")}</MenuItem>
                <MenuItem value="manufacturing">{t("manufacturing")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{t("size")}</InputLabel>
              <Select label={t("size")} name="size" value={clientData.size} onChange={handleInputChange}>
                <MenuItem value=""></MenuItem>
                <MenuItem value="small">{t("small")}</MenuItem>
                <MenuItem value="medium">{t("medium")}</MenuItem>
                <MenuItem value="large">{t("large")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label={t("notes")} name="notes" fullWidth variant="outlined" multiline rows={2} value={clientData.notes} onChange={handleInputChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClientFormDialog;
