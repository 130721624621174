// react
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Search as SearchIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const DataBoxesTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("registration_date") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left">
            <Typography fontWeight="bold">{headCell.label}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataBoxesTableToolbar = ({ handleOpen, searchQuery, handleSearchChange, isSearchOpen, setIsSearchOpen }) => {
  const { canCreateDatabox, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSearchIconClick = () => {
    setIsSearchOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" fontWeight="bold">
          {conservSostL1 ? t("my_archives") : t("my_databoxes")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        {isSearchOpen ? (
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              placeholder={t("search_by_name")}
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title={t("search")} placement="top">
                      <IconButton onClick={handleSearchIconClick}>
                        <SearchIcon fontSize={isMobile ? "small" : "medium"} />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{ sx: { py: { xs: 0.7, sm: 1.7 }, fontSize: { xs: "0.8rem", sm: "1rem" } } }}
            />
          </Grid>
        ) : (
          <Grid item>
            <Tooltip title={t("search")} placement="top">
              <IconButton onClick={handleSearchIconClick}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        {(canCreateDatabox || conservSostL1) && (
          <Grid item container sm={3} justifyContent="flex-end">
            {isMobile ? null : (
              <Button variant="contained" fullWidth sx={{ py: 1.5 }} onClick={handleOpen} startIcon={<AddIcon />} data-testid="create-databox-button">
                {conservSostL1 ? t("archive") : t("databox")}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DataBoxesTable = ({ tags, handleOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTags, setFilteredTags] = useState(tags);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    setFilteredTags(tags);
  }, [tags]);

  useEffect(() => {
    setFilteredTags(tags.filter((tag) => tag.name.toLowerCase().includes(searchQuery.toLowerCase())));
    setPage(0);
  }, [searchQuery, tags]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredTags.length) : 0;

  return (
    <Box>
      <DataBoxesTableToolbar handleOpen={handleOpen} searchQuery={searchQuery} handleSearchChange={handleSearchChange} isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      <Divider sx={{ my: 2 }} />
      <TableContainer>
        <Table data-testid="databoxes-table">
          {!isMobile && <DataBoxesTableHead />}
          <TableBody>
            {(isMobile ? filteredTags : filteredTags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((tag) => {
              return isMobile ? (
                <Box key={tag.id} onClick={() => navigate(`/${tag.id}`)} sx={{ padding: 2, borderBottom: "1px solid #e0e0e0", cursor: "pointer" }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {tag.name || "N/A"}
                  </Typography>
                  <Typography variant="body2">{tag.firstseen ? convertTimestampDate(tag.firstseen) : "N/A"}</Typography>
                </Box>
              ) : (
                <TableRow
                  key={tag.id}
                  sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}
                  onClick={() => navigate(`/${tag.id}`)}
                >
                  <TableCell align="left">{tag.name || "N/A"}</TableCell>
                  <TableCell align="left">{tag.firstseen ? convertTimestampDate(tag.firstseen) : "N/A"}</TableCell>
                </TableRow>
              );
            })}
            {!isMobile && emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={2} />
              </TableRow>
            )}
          </TableBody>
          {!isMobile && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                  colSpan={2}
                  count={filteredTags.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t("rows_per_page")}
                  labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

DataBoxesTable.propTypes = {
  tags: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default DataBoxesTable;
