// react
import React from "react";
import { Link } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { TableBody, TableCell, TableRow, IconButton, Tooltip } from "@mui/material";
import { ManageSearch as ManageSearchIcon, Verified as VerifiedIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CertificationsTableStandard = ({ signatures, handleView, checkVerification, emptyRows, rowsPerPage, page }) => {
  const { t } = useTranslation();

  const typeMap = {
    doc: t("document"),
    img: t("image"),
    info: t("note"),
    CDNFile: t("CDNFile"),
  };

  return (
    <TableBody>
      {(rowsPerPage > 0 ? signatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : signatures).map((signature) => (
        <TableRow key={signature.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
          <TableCell>
            {signature.type === "doc" || signature.type === "img" ? (
              signature.data.fileName || <span style={{ color: "gray" }}>N/A</span>
            ) : signature.type === "info" ? (
              signature.data.text || <span style={{ color: "gray" }}>N/A</span>
            ) : (
              <span style={{ color: "gray" }}>N/A</span>
            )}
          </TableCell>
          <TableCell>
            {signature.name ? (
              <Link to={`/${signature.tdr}`} style={{ textDecoration: "none", color: "primary.main" }}>
                {signature.name}
              </Link>
            ) : (
              "Not assigned"
            )}
          </TableCell>
          <TableCell>{signature.timestamp ? convertTimestamp(signature.timestamp) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
          <TableCell>{typeMap[signature.type] || <span style={{ color: "gray" }}>N/A</span>}</TableCell>
          <TableCell align="left" sx={{ color: "gray" }}>
            {signature.public ? t("public") : t("private")}
          </TableCell>
          <TableCell align="right" size="small">
            <Tooltip title={t("details")} placement="top">
              <IconButton color="primary" onClick={() => handleView(signature)}>
                <ManageSearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("verify")} placement="top">
              <IconButton color="success" onClick={() => checkVerification(signature)}>
                <VerifiedIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      ))}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </TableBody>
  );
};

CertificationsTableStandard.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkVerification: PropTypes.func.isRequired,
  emptyRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default CertificationsTableStandard;
