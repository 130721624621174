// react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Grid, Box, Paper, TextField, Button, Typography, Container, Checkbox, FormControlLabel } from "@mui/material";

// UserOperations
import postNewUser from "UserOperations/postNewUser";

// A ---------------------------------------------------------------------- M

const NewUser = () => {
  const { clientData } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [canCreateDatabox, setCanCreateDatabox] = useState(false);
  const [canCreateGroup, setCanCreateGroup] = useState(false);
  const [canSign, setCanSign] = useState(false);
  const [isReseller, setIsReseller] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [canAddUser, setCanAddUser] = useState(false);
  const [conservSostL1, setConservSostL1] = useState(false);

  const handleSave = async () => {
    await postNewUser(email, clientData ? clientData.id : "", canCreateDatabox, canCreateGroup, canSign, isReseller, isInternal, canAddUser, conservSostL1);
    navigate("/");
  };

  const handleConservSostL1Change = (checked) => {
    setConservSostL1(checked);
    if (checked) {
      setCanCreateDatabox(false);
      setCanCreateGroup(false);
      setCanSign(false);
      setIsReseller(false);
      setCanAddUser(false);
    }
  };

  const handleOtherCheckboxChange = (setter) => (checked) => {
    setter(checked);
    if (checked) {
      setConservSostL1(false);
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          mt: isMobile ? 10 : 20,
          p: isMobile ? 2 : 4,
          boxShadow: 4,
          borderRadius: "15px",
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Typography variant={isMobile ? "h6" : "h5"} fontWeight="bold" mb="5%" align="center">
          {t("add_new_user")}
        </Typography>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Email Address"
              label={t("email")}
              name="Email Address"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label={t("email")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              required
              fullWidth
              id="Customer Name"
              label={t("customer_name")}
              name="Customer Name"
              variant="outlined"
              value={clientData ? clientData.companyName || clientData.id : "N/A"}
              aria-label={t("customer_name")}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel
                  control={<Checkbox checked={canCreateDatabox} onChange={(e) => handleOtherCheckboxChange(setCanCreateDatabox)(e.target.checked)} disabled={conservSostL1} />}
                  label="canCreateDatabox"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel
                  control={<Checkbox checked={canCreateGroup} onChange={(e) => handleOtherCheckboxChange(setCanCreateGroup)(e.target.checked)} disabled={conservSostL1} />}
                  label="canCreateGroup"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel control={<Checkbox checked={canSign} onChange={(e) => handleOtherCheckboxChange(setCanSign)(e.target.checked)} disabled={conservSostL1} />} label="canSign" />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel
                  control={<Checkbox checked={isReseller} onChange={(e) => handleOtherCheckboxChange(setIsReseller)(e.target.checked)} disabled={conservSostL1} />}
                  label="isReseller"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel
                  control={<Checkbox checked={canAddUser} onChange={(e) => handleOtherCheckboxChange(setCanAddUser)(e.target.checked)} disabled={conservSostL1} />}
                  label="canAddUser"
                />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ p: 1 }} elevation={5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={conservSostL1}
                      onChange={(e) => handleConservSostL1Change(e.target.checked)}
                      disabled={canCreateDatabox || canCreateGroup || canSign || isReseller || canAddUser}
                    />
                  }
                  label="conservSostL1"
                />
              </Paper>
            </Grid>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Button variant="contained" onClick={handleSave} aria-label={t("save")} disabled={!email}>
              {t("add")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default NewUser;
