// firebase
import { db } from "config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// eventLogger
import logEvent from "eventLogger/logEvent";

// A ---------------------------------------------------------------------- M

const removeViewGroup = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const viewgroupRef = doc(db, "tagsdata", tagID, "viewgroups", groupID);
    await deleteDoc(viewgroupRef);
    await logEvent(tagID, "event_remove_viewgroup_success", timestamp, `${groupID} removed from ${tagID} successfully.`, null, userID, null, "success");
    return { code: 0 };
  } catch (error) {
    console.error(`Error in removeViewGroup for tagID ${tagID} and groupID ${groupID}:`, error.message);
    await logEvent(tagID, "event_remove_viewgroup_error", timestamp, `${groupID} removed from ${tagID} failed.`, null, userID, null, "error");
    return { code: 1 };
  }
};

export default removeViewGroup;
