// react
import React, { useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Card, CardContent, Typography, Switch, TextField, IconButton, Tooltip, Divider, Button, List, ListItem, ListItemText, ListItemSecondaryAction } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddIcon from "@mui/icons-material/Add";

// TagOperations
import updateField from "TagOperations/updateField";

// A ---------------------------------------------------------------------- M

const AlarmConfiguration = ({ tag }) => {
  const { t } = useTranslation();

  const [emails, setEmails] = useState(tag.alarmEmails || []);
  const [enabled, setEnabled] = useState(tag.alarmEnabled || false);
  const [newEmail, setNewEmail] = useState("");
  const [isAdding, setIsAdding] = useState(false);
  const [isEnabledLoading, setIsEnabledLoading] = useState(false);

  const saveEmails = async (updatedEmails) => {
    setEmails(updatedEmails);
    await updateField(tag.id, "alarm_emails", updatedEmails);
  };

  const handleAddEmail = async () => {
    if (newEmail.trim() && !emails.includes(newEmail)) {
      const updatedEmails = [...emails, newEmail.trim()];
      await saveEmails(updatedEmails);
      setNewEmail("");
      setIsAdding(false);
    }
  };

  const handleDeleteEmail = async (emailToDelete) => {
    const updatedEmails = emails.filter((email) => email !== emailToDelete);
    await saveEmails(updatedEmails);
  };

  const handleEnabledChange = async (newState) => {
    setIsEnabledLoading(true);
    setEnabled(newState);
    await updateField(tag.id, "alarm_enabled", newState);
    setIsEnabledLoading(false);
  };

  return (
    <Card variant="outlined" sx={{ borderWidth: 2, borderStyle: "solid", borderRadius: 2 }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ color: "gray" }}>
          {t("forwarding_alarms")}
        </Typography>

        <Divider sx={{ my: 2 }} />

        {/* Enabled Switch */}
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <Switch checked={enabled} onChange={(e) => handleEnabledChange(e.target.checked)} disabled={isEnabledLoading} />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        {/* Email List */}
        <Typography variant="body1" fontWeight="bold">
          {t("recipients")}
        </Typography>
        <List>
          {emails.map((email, index) => (
            <ListItem key={index} divider>
              <ListItemText primary={email} />
              <ListItemSecondaryAction>
                <Tooltip title={t("remove")} placement="top">
                  <IconButton color="error" onClick={() => handleDeleteEmail(email)} edge="end">
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>

        {/* Add New Email */}
        {isAdding ? (
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8}>
              <TextField fullWidth value={newEmail} onChange={(e) => setNewEmail(e.target.value)} placeholder={t("email")} />
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={t("save")}>
                <IconButton color="primary" onClick={handleAddEmail}>
                  <CheckIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("cancel")}>
                <IconButton color="error" onClick={() => setIsAdding(false)}>
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        ) : (
          <Button startIcon={<AddIcon />} onClick={() => setIsAdding(true)} variant="outlined">
            {t("recipient")}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default AlarmConfiguration;
