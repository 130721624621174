// firebase
import { db } from "config/firebase";
import { collection, query, where, getCountFromServer } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const fetchTotalRecordsCountWithTypes = async (tagId, types, isTagGroupMember) => {
  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");

    // Base query
    let baseQuery = query(recordsRef, where("type", "in", types));

    // If user is not a tag group member, filter public records
    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    // Use Firestore's count aggregation
    const snapshot = await getCountFromServer(baseQuery);

    // Return the total count
    return snapshot.data().count;
  } catch (error) {
    console.error("Error fetching total record count:", error.message);
    return 0;
  }
};

export default fetchTotalRecordsCountWithTypes;
