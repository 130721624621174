// react
import React, { useState, useEffect } from "react";

// prop-types
import PropTypes from "prop-types";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  useMediaQuery,
  SwipeableDrawer,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Stack,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, CheckCircle as CheckCircleIcon, Task as TaskIcon, Download as DownloadIcon } from "@mui/icons-material";

// components
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";
import { Puller } from "ui-components/Puller";

// firebase
import { db } from "config/firebase";
import { doc, getDoc } from "firebase/firestore";

// storage
import downloadXMLFile from "storage/downloadXMLFile";
import downloadAsZip from "storage/downloadAsZip";

// eventLogger
import logEvent from "eventLogger/logEvent";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const CDNPreservationProofDialog = ({ open, onClose, record }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [aicData, setAicData] = useState(null);

  useEffect(() => {
    const logger = async () => {
      await logEvent(
        record.tdr,
        "event_archive_verification_success",
        unixTimestampInSeconds(),
        "Verification successfully performed.",
        { documentID: record.txid, verificationStatus: true },
        user.uid,
        record.txid,
        "success"
      );
    };

    const fetchUploadReceipt = async () => {
      try {
        if (record && record.txid) {
          const docRef = doc(db, "uploadReceipts", record.txid);
          const docSnapshot = await getDoc(docRef);
          if (docSnapshot.exists()) {
            const receiptData = docSnapshot.data();
            setUploadReceipt({ ...receiptData });
          } else {
            console.log("No such document exists.");
          }
        }
      } catch (error) {
        console.error("Error fetching upload receipt:", error.message);
      }
    };

    const fetchAICData = async () => {
      try {
        if (record && record.AIC_ID) {
          const aicDocRef = doc(db, "aicsdata", record.AIC_ID);
          const aicDocSnapshot = await getDoc(aicDocRef);
          if (aicDocSnapshot.exists()) {
            console.log(aicDocSnapshot.data());
            setAicData(aicDocSnapshot.data());
          }
        }
      } catch (error) {
        console.error("Error fetching AIC data:", error.message);
      }
    };

    if (record) {
      logger();
      fetchUploadReceipt();
      fetchAICData();
    }
  }, [record]);

  const handleResetUploadReceipt = () => {
    setReceiptDialogOpen(false);
  };

  const PreservationProofContent = (
    <Stack spacing={3}>
      {record.AIC_ID && aicData && (
        <Card sx={{ borderLeft: "6px solid green", boxShadow: 3 }}>
          <CardContent>
            <Stack direction="row" spacing={2} alignItems="center">
              <CheckCircleIcon color="success" fontSize="large" />
              <Typography variant="body1">L'Archival Information Collection (AIC UniSincro) è stato generato, assegnato al record, firmato digitalmente e marcato temporalmente.</Typography>
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button variant="outlined" color="primary" onClick={() => downloadXMLFile(`AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC.xml`, "AIC", record.AIC_ID)} sx={{ marginRight: 1 }}>
              {t("download_aic")}
            </Button>
            <Button variant="outlined" color="primary" onClick={() => downloadXMLFile(`AICFiles/${record.AIC_ID}/${record.AIC_ID}_AIC_signed.xml.p7m`, "AIC_signed", record.AIC_ID)}>
              {t("download_signed_aic")}
            </Button>
          </CardActions>
        </Card>
      )}

      <Card sx={{ borderLeft: "6px solid green", boxShadow: 3 }}>
        <CardContent>
          <Stack direction="row" spacing={2} alignItems="center">
            <CheckCircleIcon color="success" fontSize="large" />
            <Typography variant="body1">L'Indice Di Conservazione (IdC UniSincro) è stato generato e firmato digitalmente.</Typography>
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button variant="outlined" color="primary" onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_IdC.xml`, "IdC", record.id)} sx={{ marginRight: 1 }} startIcon={<DownloadIcon />}>
            {t("download_idc")}
          </Button>
          <Button variant="outlined" color="primary" onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_IdC_signed.xml.p7m`, "IdC_signed", record.id)} startIcon={<DownloadIcon />}>
            {t("download_signed_idc")}
          </Button>
        </CardActions>
      </Card>

      <Card sx={{ borderLeft: "6px solid green", boxShadow: 3 }}>
        <CardContent>
          <Stack spacing={2}>
            {[
              { name: "gluer.xml", description: t("parameters_file") },
              { name: "metadata.xml", description: t("metadata_file") },
            ].map((file, index) => (
              <Stack key={index} direction="row" spacing={2} alignItems="center">
                <TaskIcon color="success" fontSize="large" />
                <Box flexGrow={1}>
                  <Typography>{`${t("file_name")}: ${file.name}`}</Typography>
                  <Typography>{`${t("description")}: ${file.description}`}</Typography>
                  <Typography>{`${t("mime_type")}: application/xml`}</Typography>
                </Box>
                <Button variant="outlined" onClick={() => downloadXMLFile(`${record.uploadID}/${record.id}_${file.name}`, file.name.split(".")[0], record.id)} startIcon={<DownloadIcon />}>
                  {t("download")}
                </Button>
              </Stack>
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={onClose}
          onOpen={() => {}}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "90%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight="bold" textAlign="center" mt={2} mb={3}>
              {t("preservation_proof")}
            </Typography>
            {PreservationProofContent}
            <Grid container spacing={1} mt={5}>
              <Button variant="contained" color="primary" onClick={() => setReceiptDialogOpen(true)}>
                {t("open_submission_report")}
              </Button>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => downloadAsZip(record.uploadID)} startIcon={<DownloadIcon />}>
                  {t("download_as_zip")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 1,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
          data-testid="preservation-proof-modal"
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("preservation_proof")}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title={t("close")} placement="top">
                  <IconButton onClick={onClose} color="error" edge="end">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>{PreservationProofContent}</DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setReceiptDialogOpen(true)}>
              {t("open_submission_report")}
            </Button>
            <Button variant="contained" onClick={() => downloadAsZip(record.uploadID)} startIcon={<DownloadIcon />}>
              {t("download_as_zip")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {receiptDialogOpen && uploadReceipt && <CDNFileUploadReceipt receiptDialogOpen={receiptDialogOpen} handleResetUploadReceipt={handleResetUploadReceipt} uploadReceipt={uploadReceipt} />}
    </>
  );
};

CDNPreservationProofDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
};

export default CDNPreservationProofDialog;
