// react
import React from "react";
import { Link } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { TableBody, TableCell, TableRow, IconButton, Tooltip } from "@mui/material";
import { ManageSearch as ManageSearchIcon, Verified as VerifiedIcon } from "@mui/icons-material";

// utils
import convertTimestamp from "utils/convertTimestamp";

// A ---------------------------------------------------------------------- M

const CertificationsTableConservSostL1 = ({ signatures, handleView, checkPreservationProof, emptyRows, rowsPerPage, page }) => {
  const { t } = useTranslation();

  return (
    <TableBody>
      {(rowsPerPage > 0 ? signatures.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : signatures).map((signature) => {
        const documentName = signature.metadata?.documenti?.find((doc) => doc.idDocumento === signature.metadata?.documentoPrincipale)?.nomeDocumento || <span style={{ color: "gray" }}>N/A</span>;
        return (
          <TableRow key={signature.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
            <TableCell>{documentName}</TableCell>
            <TableCell>
              {signature.name ? (
                <Link to={`/${signature.tdr}`} style={{ textDecoration: "none", color: "primary.main" }}>
                  {signature.name}
                </Link>
              ) : (
                "Not assigned"
              )}
            </TableCell>
            <TableCell>{signature.timestamp ? convertTimestamp(signature.timestamp) : <span style={{ color: "gray" }}>N/A</span>}</TableCell>
            <TableCell>{signature.tipologiaDocumentale || "N/A"}</TableCell>
            <TableCell align="right" size="small">
              <Tooltip title={t("details")} placement="top">
                <IconButton color="primary" onClick={() => handleView(signature)}>
                  <ManageSearchIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t("preservation_proof")} placement="top">
                <IconButton color="success" onClick={() => checkPreservationProof(signature)}>
                  <VerifiedIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
      {emptyRows > 0 && (
        <TableRow style={{ height: 53 * emptyRows }}>
          <TableCell colSpan={5} />
        </TableRow>
      )}
    </TableBody>
  );
};

CertificationsTableConservSostL1.propTypes = {
  signatures: PropTypes.array.isRequired,
  handleView: PropTypes.func.isRequired,
  checkPreservationProof: PropTypes.func.isRequired,
  emptyRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

export default CertificationsTableConservSostL1;
