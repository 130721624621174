// firebase
import { db } from "config/firebase";
import { doc, setDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// eventLogger
import logEvent from "eventLogger/logEvent";

// A ---------------------------------------------------------------------- M

const addAuthorizedKey = async (tagID, keyID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const authorizedKeyRef = doc(db, `tagsdata/${tagID}/authorized_keys`, keyID);

    await setDoc(authorizedKeyRef, {
      key_id: keyID,
      added_on: timestamp,
    });

    await logEvent(tagID, "event_add_datakey_success", timestamp, `${keyID} added to ${tagID} successfully.`, null, userID, null, "success");
  } catch (error) {
    console.error(`Error in addAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error.message);
    await logEvent(tagID, "event_add_datakey_error", timestamp, `${keyID} added to ${tagID} failed.`, null, userID, null, "success");
  }
};

export default addAuthorizedKey;
