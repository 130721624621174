// react
import React from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// reactflow
import { Handle, Position } from "reactflow";
import "reactflow/dist/style.css";

// @mui
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

// A ---------------------------------------------------------------------- M

const AuthorizedUsersNode = ({ data }) => {
  const { conservSostL1 } = UserAuth();

  const getPermissions = (source) => {
    if (source.includes("read/write")) {
      return "read/write";
    } else if (source.includes("read-only")) {
      return "read";
    } else {
      return "N/A";
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{
        width: "800px",
        p: 3,
        borderRadius: "8px",
        backgroundColor: "rgba(0, 47, 108, 0.9)",
        backgroundImage: "linear-gradient(135deg, #002f6c 0%, #00509e 100%)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
        color: "#ffffff",
        overflow: "hidden",
      }}
    >
      {/* Title */}
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        fontWeight="bold"
        sx={{
          color: "#ffffff",
          borderBottom: "2px solid #FFA500",
          pb: 1,
          letterSpacing: "1px",
        }}
      >
        Authorized Users
      </Typography>

      {/* Summary Box */}
      <Box
        mb={2}
        textAlign="center"
        sx={{
          backgroundColor: "rgba(255, 165, 0, 0.15)",
          borderRadius: 2,
          py: 1,
          boxShadow: "inset 0px 1px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="body1" fontWeight="bold">
          Tot. N. {!conservSostL1 ? "Certifications" : "Documents"}: {data.totalNumberOfCertifications}
        </Typography>
      </Box>

      {/* Table Section */}
      <Table size="small" sx={{ backgroundColor: "transparent" }}>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Permissions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Count
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  textTransform: "uppercase",
                  color: "#b0bec5",
                  letterSpacing: "0.5px",
                }}
              >
                Status
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.authorizedUsersArray.map((user, index) => (
            <TableRow
              key={index}
              sx={{
                "&:hover": {
                  backgroundColor: "rgba(76, 175, 80, 0.1)",
                  cursor: "pointer",
                },
                backgroundColor: user.user_number_of_certifications > 0 ? "#263238" : "#37474f",
              }}
            >
              <TableCell>
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {user.user_email}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {getPermissions(user.source)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 500,
                    color: "#eceff1",
                  }}
                >
                  {user.user_number_of_certifications}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={user.user_number_of_certifications > 0 ? "Certification Active" : "No Certifications"} arrow>
                  {user.user_number_of_certifications > 0 ? (
                    <CheckCircleOutlineIcon
                      sx={{
                        color: "#4caf50",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  ) : (
                    <CancelIcon
                      sx={{
                        color: "#f44336",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                      }}
                    />
                  )}
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* ReactFlow Handle */}
      <Handle
        type="source"
        position={Position.Right}
        id="users-source"
        style={{
          background: "#4caf50",
          border: "2px solid #ffffff",
          width: "12px",
          height: "12px",
        }}
      />
    </Card>
  );
};

export default AuthorizedUsersNode;
