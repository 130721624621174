// react
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { useMediaQuery, Grid, Typography, Button, Stack, IconButton, Accordion, AccordionSummary, AccordionDetails, Divider, Snackbar, Alert } from "@mui/material";
import {
  NotificationAdd as NotificationAddIcon,
  NotificationsNone as NotificationsNoneIcon,
  QrCode as QrCodeIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Settings as SettingsIcon,
  Stream as StreamIcon,
  ExpandMore as ExpandMoreIcon,
  CallReceived as CallReceivedIcon,
  AlarmAdd as AlarmAddIcon,
  AlarmOff as AlarmOffIcon,
} from "@mui/icons-material";

// components
import TagQRCode from "./TagQRCode";

// utils
import convertTimestampDate from "utils/convertTimestampDate";

// A ---------------------------------------------------------------------- M

const TagToolbar = ({ tag, isFollowing, handleFollowToggle, alarmOn, handleAlarmToggle }) => {
  const { user, conservSostL1 } = UserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openQR, setOpenQR] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  const handleQRCode = () => {
    setOpenQR(true);
    window.history.pushState(null, "");
  };

  const handleAccordionToggle = () => {
    setAccordionOpen((prev) => !prev);
  };

  const showSnackbar = (message, severity) => {
    setSnackbarOpen(false);
    setTimeout(() => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    }, 100);
  };

  const handleFollowToggleWithSnackbar = () => {
    handleFollowToggle();
    showSnackbar(isFollowing ? t("unfollow_success") : t("follow_success"), isFollowing ? "info" : "success");
  };

  const handleAlarmToggleWithSnackbar = () => {
    handleAlarmToggle();
    showSnackbar(alarmOn ? t("alarm_disabled_success") : t("alarm_enabled_success"), alarmOn ? "info" : "success");
  };

  const TagInformation = ({ tag, conservSostL1 }) => (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body2">
            <b>{t("id")}:</b> {tag.id}
          </Typography>
          <Typography variant="body2">
            <b>{t("name")}:</b> {tag.name || "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("description")}:</b> {tag.notes || "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("registration_date")}:</b> {tag.firstseen ? convertTimestampDate(tag.firstseen) : "N/A"}
          </Typography>
          <Typography variant="body2">
            <b>{t("owner")}:</b> {tag.tagOwnerEmail || "N/A"}
          </Typography>
          {conservSostL1 && (
            <Typography variant="body2">
              <b>{t("document_type")}:</b> {tag.tipologiaDocumentale || "N/A"}
            </Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} data-testid="databox-information-card">
        <Grid item xs={12}>
          <Accordion expanded={accordionOpen} onChange={handleAccordionToggle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" fontWeight="bold">
                {tag.name || "N/A"}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Divider sx={{ my: 2 }} />
              <TagInformation tag={tag} conservSostL1={conservSostL1} />
            </AccordionDetails>
          </Accordion>
        </Grid>
        {isMobile ? (
          <Grid item container xs={12} justifyContent="flex-start">
            <Grid item>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}`)}>
                <PlaylistAddCheckIcon fontSize="large" />
              </IconButton>
            </Grid>
            {tag.tagOwner === user.uid && (
              <>
                <Grid item>
                  <IconButton color="primary" onClick={() => navigate(`/${tag.id}/requests`)}>
                    <CallReceivedIcon fontSize="large" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton color="primary" onClick={() => navigate(`/${tag.id}/settings`)}>
                    <SettingsIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </>
            )}
            <Grid item>
              <IconButton color="primary" onClick={() => navigate(`/${tag.id}/monitor`)}>
                <StreamIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleQRCode}>
                <QrCodeIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleFollowToggleWithSnackbar}>
                {isFollowing ? <NotificationsNoneIcon fontSize="large" /> : <NotificationAddIcon fontSize="large" />}
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color="primary" onClick={handleAlarmToggleWithSnackbar}>
                {alarmOn ? <AlarmOffIcon fontSize="large" /> : <AlarmAddIcon fontSize="large" />}
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid item container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<PlaylistAddCheckIcon fontSize="large" />} variant="contained" color="primary" fullWidth onClick={() => navigate(`/${tag.id}`)}>
                {conservSostL1 ? t("documents") : t("certifications")}
              </Button>
            </Grid>
            {tag.tagOwner === user.uid && (
              <>
                {!conservSostL1 && (
                  <Grid item xs={12} sm={6} md={3} lg={1.5}>
                    <Button startIcon={<CallReceivedIcon fontSize="large" />} variant="contained" color="primary" fullWidth onClick={() => navigate(`/${tag.id}/requests`)}>
                      {t("requests")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={3} lg={1.5}>
                  <Button startIcon={<SettingsIcon fontSize="large" />} variant="contained" fullWidth onClick={() => navigate(`/${tag.id}/settings`)}>
                    {t("settings")}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<StreamIcon fontSize="large" />} variant="contained" fullWidth onClick={() => navigate(`/${tag.id}/monitor`)}>
                {t("monitor")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={1.5}>
              <Button startIcon={<QrCodeIcon fontSize="large" />} variant="contained" fullWidth onClick={handleQRCode}>
                {t("qr_code")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button
                startIcon={isFollowing ? <NotificationsNoneIcon fontSize="large" /> : <NotificationAddIcon fontSize="large" />}
                variant="contained"
                fullWidth
                onClick={handleFollowToggleWithSnackbar}
              >
                {isFollowing ? t("unfollow") : t("follow")}
              </Button>
            </Grid>
            {!conservSostL1 && (
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Button startIcon={alarmOn ? <AlarmOffIcon fontSize="large" /> : <AlarmAddIcon fontSize="large" />} variant="contained" fullWidth onClick={handleAlarmToggleWithSnackbar}>
                  {alarmOn ? t("disable_alarm") : t("enable_alarm")}
                </Button>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <TagQRCode tag={tag.id} open={openQR} setOpen={setOpenQR} />

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default TagToolbar;
