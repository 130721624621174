// firebase
import { db } from "config/firebase";
import { collection, query, where, getCountFromServer } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const fetchTotalRecordsCount = async (tagId, type, isTagGroupMember, criteria) => {
  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");

    // Base query
    let baseQuery = query(recordsRef, where("type", "==", type));

    // If user is not a tag group member, filter public records
    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    // Apply criteria dynamically
    if (criteria) {
      Object.entries(criteria).forEach(([key, value]) => {
        if (value) {
          if (key === "startDate") {
            baseQuery = query(baseQuery, where("timestamp", ">=", value));
          } else if (key === "endDate") {
            baseQuery = query(baseQuery, where("timestamp", "<=", value));
          } else {
            baseQuery = query(baseQuery, where(key, "==", value));
          }
        }
      });
    }

    // Use Firestore's count aggregation
    const snapshot = await getCountFromServer(baseQuery);

    // Return the total count
    return snapshot.data().count;
  } catch (error) {
    console.error("Error fetching total record count:", error.message);
    return 0;
  }
};

export default fetchTotalRecordsCount;
