// firebase
import { db } from "config/firebase";
import { collection, addDoc, doc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const addForwardRoute = async (tagID, body) => {
  try {
    const { endpoint, type, status, selectedTypes, headers } = body;
    const tagRef = doc(db, "tagsdata", tagID);
    const forwardRoutesRef = collection(tagRef, "forward_routes");
    const timestamp = unixTimestampInSeconds();

    const docRef = await addDoc(forwardRoutesRef, {
      endpoint,
      type,
      enabled: status,
      types: selectedTypes,
      headers,
      added_on: timestamp,
      last_modified: timestamp,
    });

    console.log(`Document with ID ${docRef.id} has been successfully added to tag ID ${tagID}.`);
  } catch (error) {
    console.error(`Error in addForwardRoute for tagID ${tagID}:`, error.message);
  }
};

export default addForwardRoute;
