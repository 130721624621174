// react
import React from "react";

// prop-types
import PropTypes from "prop-types";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Typography, Tooltip, IconButton } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// A ---------------------------------------------------------------------- M

const CDNSearchDialog = ({ open, onClose, onSearch }) => {
  const { t } = useTranslation();

  const [criteria, setCriteria] = React.useState({
    startDate: null,
    endDate: null,
  });

  const handleChange = (name) => (eventOrValue) => {
    const value = eventOrValue?.target ? eventOrValue.target.value : eventOrValue;
    setCriteria((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    const unixCriteria = {
      ...criteria,
      startDate: criteria.startDate ? Math.floor(new Date(criteria.startDate).getTime() / 1000).toString() : null,
      endDate: criteria.endDate ? Math.floor(new Date(criteria.endDate).getTime() / 1000).toString() : null,
    };
    onSearch(unixCriteria);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 1,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("search")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={onClose} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("start_date")} value={criteria.startDate} onChange={handleChange("startDate")} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("end_date")} value={criteria.endDate} onChange={handleChange("endDate")} />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onClose}>
          {t("cancel")}
        </Button>
        <Button variant="contained" onClick={handleSearch}>
          {t("search")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CDNSearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

export default CDNSearchDialog;
