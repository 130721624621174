// react
import React, { useEffect } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// qrcode.react
import { QRCodeSVG } from "qrcode.react";

// @mui
import { Box, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, List, ListItem, ListItemText, IconButton, Typography, useMediaQuery, Tooltip } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon, Print as PrintIcon } from "@mui/icons-material";

// components
import { Puller } from "ui-components/Puller";

// A ---------------------------------------------------------------------- M

const TagQRCode = ({ tag, open, setOpen }) => {
  const { conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=600,height=400");
    const qrCode = document.querySelector("#qr-code-svg");
    printWindow.document.open();
    printWindow.document.write(`
	  <html>
		<head>
		  <title>Print tag code</title>
		  <style>
			@media print {
			  body * {
				visibility: hidden;
			  }
			  #qr-code-svg,
			  #qr-code-svg * {
				visibility: visible;
			  }
			  #qr-code-svg {
				position: absolute;
				left: 250;
				top: 50;
			  }
			}
			.close-button {
				position: fixed;
				top: 20px;
				right: 20px;
				padding: 12px 24px;
				font-size: 45px;
				font-weight: bold;
				background-color: #e0e0e0;
				border: none;
				cursor: pointer;
			  }
		  </style>
		</head>
		<body>
		  <button class="close-button" onclick="window.close()">Close window</button>
		  ${qrCode.outerHTML}
		</body>
	  </html>
	`);
    printWindow.document.close();
    printWindow.print();
    setOpen(false);
  };

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        setOpen(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const printForm = (
    <Grid container>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText primary={t("qr_code_steps_1")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={t("qr_code_steps_2")} />
          </ListItem>
          <ListItem>
            <ListItemText primary={conservSostL1 ? t("qr_code_steps_3") : t("qr_code_steps_4")} />
          </ListItem>
        </List>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <QRCodeSVG id="qr-code-svg" style={{ height: 200, width: 200 }} value={`${process.env.REACT_APP_ENVIRONMENT_URL}/${tag}`} />
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "90%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
    >
      <Puller />
      <Box sx={{ p: 3 }}>
        <Typography variant="h5" fontWeight="bold" textAlign="center" mt={2} mb={3}>
          {t("qr_code")}
        </Typography>
        {printForm}
        <Grid container spacing={1} mt={5}>
          <Grid item xs={12}>
            <Button fullWidth variant="contained" startIcon={<PrintIcon />} onClick={handlePrint}>
              {t("print")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  ) : (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 0.5,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("qr_code")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={() => setOpen(false)} edge="end" sx={{ color: "red" }}>
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid item container xs={12}>
          {printForm}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={() => setOpen(false)}>
          {t("close")}
        </Button>
        <Button variant="contained" onClick={handlePrint}>
          {t("print")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagQRCode;
