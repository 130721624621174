// Firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, query, where, getDocs, updateDoc } from "firebase/firestore";

// Functions
import { genRndStringPrintable } from "SafeTwin/crypto/cryptolibsodium";

// A ---------------------------------------------------------------------- M

const postNewUser = async (email, clientID, canCreateDatabox, canCreateGroup, canSign, isReseller, isInternal, canAddUser, conservSostL1) => {
  try {
    const usercontactsRef = collection(db, "usercontacts");
    const q = query(usercontactsRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      const randomIndex = `awaiting_${genRndStringPrintable(10)}`;
      await setDoc(doc(usercontactsRef, randomIndex), {
        email: email,
        clientID: clientID,
        processed: false,
        canCreateDatabox: canCreateDatabox,
        canCreateGroup: canCreateGroup,
        canSign: canSign,
        isReseller: isReseller,
        isInternal: isInternal,
        canAddUser: canAddUser,
        conservSostL1: conservSostL1,
      });
    } else {
      querySnapshot.forEach(async (document) => {
        const docId = document.id;
        const userdataRef = doc(db, "userdata", docId);
        await updateDoc(userdataRef, {
          canCreateDatabox: canCreateDatabox,
          canCreateGroup: canCreateGroup,
          canSign: canSign,
          isReseller: isReseller,
          isInternal: isInternal,
          canAddUser: canAddUser,
          conservSostL1: conservSostL1,
        });
      });
    }
  } catch (error) {
    console.error("Error in addNewUser:", error);
  }
};

export default postNewUser;
