// react
import React, { useState, useEffect, useCallback } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// @mui
import { Grid, Box, IconButton, Dialog, Typography, CircularProgress, useMediaQuery, Tooltip } from "@mui/material";
import { KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon, KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon, CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import CertificationsTable from "ui-components/CertificationManagement/CertificationsTable";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import CDNFileDetailsDialog from "ui-components/DataTypeManagement/CDNFile/CDNFileDetailsDialog";
import CDNPreservationProofDialog from "ui-components/DataTypeManagement/CDNFile/CDNPreservationProofDialog";
import { LoadingDialog } from "ui-components/LoadingComponent";

// UserOperations
import fetchLastSignatures from "UserOperations/fetchLastSignatures";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const MyCertifications = () => {
  const { user, conservSostL1 } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [signatures, setSignatures] = useState([]);
  const [record, setRecord] = useState(null);
  const [verification, setVerification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [dialogs, setDialogs] = useState({ view: false, verify: false, preservationProof: false });

  const toggleDialog = (dialog, state = true) => {
    setDialogs((prev) => ({ ...prev, [dialog]: state }));

    if (state) {
      window.history.pushState(null, "");
    }
  };

  const fetchUserData = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const signatures = await fetchLastSignatures(user.uid);
      setSignatures(signatures.sort((a, b) => b.timestamp - a.timestamp));
    } catch (error) {
      console.log("Error in fetchUserData:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchUserData();
  }, [user, fetchUserData]);

  useEffect(() => {
    const handlePopState = () => {
      if (dialogs.view) {
        toggleDialog("view", false);
      } else if (dialogs.verify) {
        toggleDialog("verify", false);
      } else if (dialogs.preservationProof) {
        toggleDialog("preservationProof", false);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, [dialogs.view, dialogs.verify, dialogs.preservationProof]);

  const handleView = (record) => {
    setRecord(record);
    toggleDialog("view");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);
      const verification = await verifySignature(user?.uid || false, record, record.type);
      setVerification(verification);
      toggleDialog("verify");
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  const checkPreservationProof = (record) => {
    setRecord(record);
    toggleDialog("preservationProof");
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      }

      const blob = await response.blob();
      const fileType = blob.type;

      setSelectedFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "application/json") {
        const jsonText = await blob.text();
        try {
          const parsedJson = JSON.parse(jsonText);
          setTextContent(parsedJson);
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
        setSelectedFile(fileUrl);
      } else if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
        setSelectedFile(fileUrl);
      } else if (fileType === "video/webm" || fileType === "video/x-matroska" || fileType === "video/mp4") {
        setSelectedFile(fileUrl);
      } else {
        throw new Error("Unsupported file type");
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error.message);
    }
  };

  const renderFileDialog = () => {
    if (!selectedFileType) {
      return null;
    }

    switch (selectedFileType) {
      case "application/pdf":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        try {
          const rows = textContent.split("\n").map((row) => row.split(","));
          return (
            <Dialog
              open={Boolean(selectedFile)}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <table>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Dialog>
          );
        } catch {
          return (
            <Dialog
              open={Boolean(selectedFile)}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography>{textContent}</Typography>
              </Box>
            </Dialog>
          );
        }

      case "application/json":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <pre>{JSON.stringify(textContent, null, 2)}</pre>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Tooltip placement="top" title={t("close")}>
              <IconButton
                color="error"
                sx={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  zIndex: 1,
                }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
            <img src={selectedFile} alt="img" />
          </Dialog>
        );

      case "video/webm":
      case "video/x-matroska":
      case "video/mp4":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 2 }}>
              <video
                src={selectedFile}
                controls
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              >
                Your browser does not support the video tag.
              </video>
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return isLoading ? (
    <Grid item container justifyContent="center" mt="30%">
      <CircularProgress />
    </Grid>
  ) : (
    <Grid item xs={12}>
      <CertificationsTable signatures={signatures} handleView={handleView} checkVerification={checkVerification} checkPreservationProof={checkPreservationProof} />

      {verificationLoading && <LoadingDialog open={verificationLoading} />}

      {record &&
        (conservSostL1 ? (
          <>
            <CDNFileDetailsDialog record={record} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
            <CDNPreservationProofDialog
              open={dialogs.preservationProof}
              setOpen={() => toggleDialog("preservationProof", false)}
              onClose={() => toggleDialog("preservationProof", false)}
              record={record}
            />
          </>
        ) : (
          <DataDialog data={{ ...record, databoxName: record.name }} open={dialogs.view} onClose={() => toggleDialog("view", false)} handleFileClick={handleFileClick} />
        ))}

      {verification && <DatapointIntegrityInspector verification={verification} open={dialogs.verify} setOpen={() => toggleDialog("verify", false)} />}

      {renderFileDialog()}
    </Grid>
  );
};

export default MyCertifications;
