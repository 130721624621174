// firebase
import { db } from "config/firebase";
import { collection, setDoc, doc, getDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// eventLogger
import logEvent from "eventLogger/logEvent";

// A ---------------------------------------------------------------------- M

const addViewGroupReadOnly = async (tagID, groupID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const tagRef = doc(db, "tagsdata", tagID);
    const viewgroupsRef = collection(tagRef, "viewgroups_readonly");
    const newViewGroupRef = doc(viewgroupsRef, groupID);
    const newViewGroupSnapshot = await getDoc(newViewGroupRef);

    if (newViewGroupSnapshot.exists()) {
      return { code: -1 };
    }

    await setDoc(newViewGroupRef, {
      group_id: groupID,
      added_on: timestamp,
    });

    await logEvent(tagID, "event_add_viewgroup_success", timestamp, `${groupID} added to ${tagID} read-only successfully.`, null, userID, null, "success");
    return { code: 0 };
  } catch (error) {
    console.error(`Error in addViewGroupReadOnly for tagID ${tagID} and groupID ${groupID}:`, error.message);
    await logEvent(tagID, "event_add_viewgroup_error", timestamp, `${groupID} added to ${tagID} read-only failed.`, null, userID, null, "error");
    return { code: -2 };
  }
};

export default addViewGroupReadOnly;
