// firebase
import { db } from "config/firebase";
import { collection, query, where, orderBy, limit, startAfter, getDocs } from "firebase/firestore";

// RecordOperations
import fetchSignature from "./fetchSignature";

// A ---------------------------------------------------------------------- M

const fetchPaginatedRecords = async (tagId, type, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order, criteria) => {
  console.log(tagId, type, isTagGroupMember, lastDoc, rowsPerPage, _orderBy, _order, criteria);

  try {
    const recordsRef = collection(db, "tagsdata", tagId, "signatures");

    // Base query
    let baseQuery = query(recordsRef, where("type", "==", type), orderBy(_orderBy, _order));

    // If user is not a tag group member, filter public records
    if (!isTagGroupMember) {
      baseQuery = query(baseQuery, where("public", "==", true));
    }

    // Apply criteria dynamically
    if (criteria) {
      Object.entries(criteria).forEach(([key, value]) => {
        if (value) {
          if (key === "startDate") {
            baseQuery = query(baseQuery, where("timestamp", ">=", value));
          } else if (key === "endDate") {
            baseQuery = query(baseQuery, where("timestamp", "<=", value));
          } else {
            baseQuery = query(baseQuery, where(key, "==", value));
          }
        }
      });
    }

    // Pagination: Apply `startAfter` if lastDoc exists
    if (lastDoc) {
      baseQuery = query(baseQuery, startAfter(lastDoc));
    }

    // Limit the results
    const limitedQuery = query(baseQuery, limit(rowsPerPage));

    // Execute the query
    const snapshot = await getDocs(limitedQuery);

    // Fetch and map results to signatures
    const recordPromises = snapshot.docs.map(async (sig) => {
      const signatureData = sig.data();
      const txid = signatureData.txid;

      // Fetch signature details
      const signature = await fetchSignature(txid);
      return signature;
    });

    const records = await Promise.all(recordPromises);

    console.log("Fetched records:", records);

    return {
      records: records.filter((record) => record !== undefined),
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching paginated records:", error.message);
    return { records: [], lastDoc: null };
  }
};

export default fetchPaginatedRecords;
