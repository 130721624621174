// react
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

// prop-types
import PropTypes from "prop-types";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TableFooter,
  TablePagination,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  FirstPageOutlined as FirstPageOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  LastPageOutlined as LastPageOutlinedIcon,
  Add as AddIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";

// components
import { GroupRemoveConfirm } from "ui-components/ORFeedbacks";

// TagOperations
import getTagOwner from "TagOperations/getTagOwner";
import removeViewGroup from "TagOperations/removeViewGroup";
import removeViewGroupReadOnly from "TagOperations/removeViewGroupReadOnly";

// Utils
import convertTimestamp from "utils/convertTimestamp";

// ----------------------------------------------------------------------

const ViewGroupsTableHead = () => {
  const { t } = useTranslation();

  const headCells = useMemo(
    () => [
      { id: "name", label: t("name") },
      { id: "timestamp", label: t("creation_date") },
      { id: "permissions", label: t("permissions") },
      { id: "actions", label: t("actions") },
    ],
    [t]
  );

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id !== "actions" ? "left" : "right"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ViewGroupsTableToolbar = ({ openAddViewGroupDrawer, tagOwner }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h6" fontWeight="bold" sx={{ color: "gray" }}>
          {t("authorized_groups")}
        </Typography>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={1} justifyContent="flex-end" alignItems="center">
        <Grid item xs={12} sm={9} />
        {tagOwner === user.uid && (
          <Grid item container sm={3} justifyContent="flex-end">
            <Button variant="outlined" fullWidth onClick={openAddViewGroupDrawer} startIcon={<AddIcon />}>
              {t("group")}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();
  const isRtl = theme.direction === "rtl";

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);
  const handleBackButtonClick = (event) => onPageChange(event, page - 1);
  const handleNextButtonClick = (event) => onPageChange(event, page + 1);
  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {isRtl ? <LastPageOutlinedIcon /> : <FirstPageOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {isRtl ? <KeyboardArrowRightOutlinedIcon /> : <KeyboardArrowLeftOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {isRtl ? <KeyboardArrowLeftOutlinedIcon /> : <KeyboardArrowRightOutlinedIcon />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {isRtl ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
      </IconButton>
    </Box>
  );
};

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ViewGroupsTable = ({ tag, groups, openAddViewGroupDrawer, handleOpenGroupRemovedSuccessful, handleOpenGroupRemovedError }) => {
  console.log("Groups:", groups);

  const { user } = UserAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tagOwner, setTagOwner] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    const fetchTagOwner = async () => {
      const tagOwner = await getTagOwner(tag.id);
      setTagOwner(tagOwner);
    };

    fetchTagOwner();
  }, [tag]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleShowRemove = (group) => {
    setShowRemove(true);
    setSelectedGroup(group);
  };

  const handleRemoveGroup = async () => {
    try {
      if (selectedGroup.permissions === "r/w") {
        await removeViewGroup(tag.id, selectedGroup.id, user.uid);
      } else if (selectedGroup.permissions === "r") {
        await removeViewGroupReadOnly(tag.id, selectedGroup.id, user.uid);
      }
      handleOpenGroupRemovedSuccessful();
    } catch (error) {
      console.error("Error in handleRemoveGroup:", error.message);
      handleOpenGroupRemovedError();
    } finally {
      setShowRemove(false);
    }
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - groups.length) : 0;

  return (
    <>
      <Box>
        <ViewGroupsTableToolbar openAddViewGroupDrawer={openAddViewGroupDrawer} tagOwner={tagOwner} />
        <br />
        <Divider />
        <br />
        <TableContainer>
          <Table size="small" data-testid="viewgroups-table">
            <ViewGroupsTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : groups).map((group) => {
                return (
                  <TableRow key={group.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" }, cursor: "pointer", "&:hover": { backgroundColor: "#bbdefb" } }}>
                    <TableCell align="left" onClick={() => navigate(`/${group.id}/group-settings`)}>
                      {group.name || "N/A"}
                    </TableCell>
                    <TableCell align="left" onClick={() => navigate(`/${group.id}/group-settings`)}>
                      {group.added_on ? convertTimestamp(group.added_on) : "N/A"}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "gray" }} onClick={() => navigate(`/${group.id}/group-settings`)}>
                      {group.permissions === "r/w" ? "read/write" : "read"}
                    </TableCell>
                    <TableCell align="right">
                      <Tooltip title={t("remove")} placement="top">
                        <IconButton color="error" onClick={() => handleShowRemove(group)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={4} />
                </TableRow>
              )}
            </TableBody>
            {!isMobile && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10, 20, { label: "All", value: -1 }]}
                    colSpan={4}
                    count={groups.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("rows_per_page")}
                    labelDisplayedRows={({ from, to, count }) => t("displayed_rows", { from, to, count: count !== -1 ? count : `more than ${to}` })}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
          {isMobile && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <TablePaginationActions count={groups.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />
            </Box>
          )}
        </TableContainer>
      </Box>
      <GroupRemoveConfirm showRemove={showRemove} setShowRemove={setShowRemove} handleRemoveGroup={handleRemoveGroup} />
    </>
  );
};

ViewGroupsTable.propTypes = {
  tag: PropTypes.object.isRequired,
  groups: PropTypes.array.isRequired,
  openAddViewGroupDrawer: PropTypes.func.isRequired,
};

export default ViewGroupsTable;
