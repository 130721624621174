// react
import React, { useEffect, useState } from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Dialog, DialogContent, DialogTitle, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormLabel, FormControl, Typography, Tooltip, IconButton } from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// utils
import exportCSV from "utils/exportCSV";

// A ---------------------------------------------------------------------- M

const MpsExportCSV = ({ tag, open, handleClose, isTagGroupMember }) => {
  const { t } = useTranslation();

  const [magnitudes, setMagnitudes] = useState([]);
  const [selectedMagnitude, setSelectedMagnitude] = useState("");
  const [startDate, setStartDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      const records = await fetchRecordsByType(tag, "mps", isTagGroupMember, startDate, endDate);
      const magnitudeRecords = records.reduce((acc, record) => {
        const { magnitude } = record.data;
        acc[magnitude] = [...(acc[magnitude] || []), record].sort((a, b) => a.data.timestamp - b.data.timestamp);
        return acc;
      }, {});

      if (Object.keys(magnitudeRecords).length !== 0) {
        setMagnitudes(Object.keys(magnitudeRecords));
        setSelectedMagnitude(Object.keys(magnitudeRecords)[0]);
      } else {
        setMagnitudes([]);
        setSelectedMagnitude("");
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const handleChange = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const handleExportCSV = async () => {
    const records = await fetchRecordsByType(tag, "mps", isTagGroupMember, startDate, endDate);
    const csvArray = records.map((record) => {
      return {
        id: record.id,
        timestamp: record.data.timestamp,
        value: record.data.value,
        uom: record.data.uom,
      };
    });
    exportCSV(csvArray.reverse());
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "15px",
          padding: 0.5,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
        },
      }}
    >
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("export")}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t("close")} placement="top">
              <IconButton onClick={handleClose} color="error" edge="end">
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("start_date")} value={startDate} onChange={(date) => setStartDate(date)} />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker label={t("end_date")} value={endDate} onChange={(date) => setEndDate(date)} />
            </LocalizationProvider>
          </Grid>
          {magnitudes.length !== 0 && (
            <Grid item xs={12} sm={4}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                <FormLabel>{t("select_parameter")}</FormLabel>
                <RadioGroup value={selectedMagnitude} onChange={handleChange}>
                  {magnitudes.map((magnitude) => {
                    return <FormControlLabel key={magnitude} value={magnitude} control={<Radio />} label={magnitude} />;
                  })}
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text">
          {t("cancel")}
        </Button>
        <Button disabled={selectedMagnitude === ""} onClick={handleExportCSV} variant="contained">
          {t("export")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MpsExportCSV;
