// react
import React from "react";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

// A ---------------------------------------------------------------------- M

const ClientDetails = ({ client }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h6" p={isMobile ? "3%" : "0%"} fontWeight="bold" gutterBottom>
        {t("customer_details")}
      </Typography>
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("company")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("company_name")}</b>: {client.companyName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("vat_number")}</b>: {client.VATNumber || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("email")}</b>: {client.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("phone")}</b>: {client.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("administrator")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("first_name")}</b>: {client.adminUser?.firstName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("last_name")}</b>: {client.adminUser?.lastName || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("email")}</b>: {client.adminUser?.email || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("phone")}</b>: {client.adminUser?.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item container xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom color="gray">
                {t("address")}
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("street")}</b>: {client.address?.street || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("city")}</b>: {client.address?.city || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("postal_code")}</b>: {client.address?.postalCode || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
                  <b>{t("country")}</b>: {client.address?.country || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
  );
};

export default ClientDetails;
