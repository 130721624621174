// firebase
import { db } from "config/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const fetchAlarmTriggers = async (databoxID) => {
  try {
    const triggersRef = collection(db, "tagsdata", databoxID, "triggers");
    const triggersQuery = query(triggersRef, orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(triggersQuery);
    const triggers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return triggers;
  } catch (error) {
    console.error("Error fetching alarm triggers:", error.message);
    throw new Error("Failed to fetch alarm triggers");
  }
};

export default fetchAlarmTriggers;
