// firebase
import { db } from "config/firebase";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Adds a follower to a tag's followers list.
 *
 * @param {string} userID - The ID of the user to add.
 * @param {string} tagID - The ID of the tag to follow.
 */
export const addFollower = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      await updateDoc(tagDocRef, {
        followers: arrayUnion(userID),
      });
      console.log(`Follow ${tagID}`);
    }
  } catch (error) {
    console.error(`Error in addFollower for uid ${userID} and tag ${tagID}:`, error.message);
  }
};

/**
 * Removes a follower from a tag's followers list.
 *
 * @param {string} userID - The ID of the user to remove.
 * @param {string} tagID - The ID of the tag to unfollow.
 */
export const removeFollower = async (userID, tagID) => {
  try {
    const tagDocRef = doc(db, "tagsdata", tagID);
    const tagDocSnapshot = await getDoc(tagDocRef);

    if (tagDocSnapshot.exists()) {
      const { followers = [] } = tagDocSnapshot.data();

      if (followers.includes(userID)) {
        const updatedFollowersArray = followers.filter((followerUid) => followerUid !== userID);
        await updateDoc(tagDocRef, { followers: updatedFollowersArray });
        console.log(`Unfollow ${tagID}`);
      }
    }
  } catch (error) {
    console.error(`Error in removeFollower for uid ${userID} and tag ${tagID}:`, error.message);
  }
};
