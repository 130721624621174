// react
import React, { useState, useEffect, useCallback } from "react";

// prop-types
import PropTypes from "prop-types";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import { Grid, Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { FileDownload as FileDownloadIcon, Search as SearchIcon, Autorenew as AutorenewIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

// components
import MpsTable from "./MpsTable";
import MpsChart from "./MpsChart";
import MpsExportCSV from "./MpsExportCSV";
import DataDialog from "ui-components/CertificationManagement/DataDialog";
import DatapointIntegrityInspector from "ui-components/CertificationManagement/DatapointIntegrityInspector";
import { LoadingDialog } from "ui-components/LoadingComponent";

// firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// Verificator
import verifySignature from "Verificator/verifySignature";

// A ---------------------------------------------------------------------- M

const MpsViewer = ({ tag, isTagGroupMember }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();

  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState();
  const [verification, setVerification] = useState();
  const [selectedSensors, setSelectedSensors] = useState([]);
  const [commonMagnitudes, setCommonMagnitudes] = useState([]);
  const [selectedMagnitude, setSelectedMagnitude] = useState("");
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openCsvModal, setOpenCsvModal] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000));
  const [endDate, setEndDate] = useState(new Date());
  const [isRealTime, setIsRealTime] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [verificationLoading, setVerificationLoading] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      if (openVerify) {
        setOpenVerify(false);
      } else if (openView) {
        setOpenView(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [openVerify, openView]);

  const fetchData = useCallback(async (start, end, isRealtimeUpdate = false) => {
    try {
      if (!isRealtimeUpdate) {
        setIsLoading(true);
      }
      const newRecords = await fetchRecordsByType(tag.id, "mps", isTagGroupMember, start, end);
      processRecords(newRecords);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      if (!isRealtimeUpdate) {
        setIsLoading(false);
      }
    }
  }, []);

  const processRecords = (records) => {
    let groupedRecords = Object.values(
      records.reduce((accumulator, currentRecord) => {
        const sensorId = currentRecord.creator_uuid;
        const sensorName = currentRecord.creator_email || sensorId;
        const magnitude = currentRecord.data.magnitude || currentRecord.data.uom;

        if (sensorId != null) {
          if (!accumulator[sensorId]) {
            accumulator[sensorId] = { sensorId, sensorName, magnitudes: [] };
          }

          const existingMagnitudeGroup = accumulator[sensorId].magnitudes.find((group) => group.magnitude === magnitude);
          if (!existingMagnitudeGroup) {
            accumulator[sensorId].magnitudes.push({
              magnitude,
              records: [currentRecord],
            });
          } else {
            existingMagnitudeGroup.records.push(currentRecord);
          }
        }

        return accumulator;
      }, {})
    );

    groupedRecords = groupedRecords.filter((sensor) => sensor.sensorId != null);
    groupedRecords.forEach((sensor) => {
      sensor.magnitudes.forEach((magnitudeGroup) => {
        magnitudeGroup.records.sort((a, b) => a.data.timestamp_end - b.data.timestamp_end);
      });
    });

    setRecords(groupedRecords);
  };

  useEffect(() => {
    if (isRealTime) {
      const tagsdataDocRef = doc(db, "tagsdata", tag.id);
      const signaturesCollectionRef = collection(tagsdataDocRef, "signatures");
      const currentTimestampInSecondsString = (Date.now() / 1000).toString();
      const newAdditionsQuery = query(signaturesCollectionRef, where("type", "==", "mps"), where("timestamp", ">", currentTimestampInSecondsString));

      const newAdditionsUnsubscribe = onSnapshot(newAdditionsQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            fetchData(new Date(Date.now() - 24 * 60 * 60 * 1000), new Date(), true);
          }
        });
      });

      fetchData(new Date(Date.now() - 24 * 60 * 60 * 1000), new Date());

      return () => {
        newAdditionsUnsubscribe();
      };
    }
  }, [isRealTime]);

  const resetStates = () => {
    setRecords([]);
    setSelectedSensors([]);
    setCommonMagnitudes([]);
    setSelectedMagnitude("");
    setChartData({ labels: [], datasets: [] });
  };

  const handleSearch = () => {
    setIsRealTime(false);
    resetStates();
    fetchData(startDate, endDate);
  };

  const handleRealTime = () => {
    setIsRealTime(true);
    resetStates();
    fetchData(new Date(Date.now() - 24 * 60 * 60 * 1000), new Date());
  };

  useEffect(() => {
    if (records.length > 0) {
      const allSensors = records.map((record) => record.sensorId);
      setSelectedSensors(allSensors);
    }
  }, [records]);

  useEffect(() => {
    const allMagnitudes = new Set();
    if (selectedSensors.length > 0) {
      selectedSensors.forEach((sensorId) => {
        const sensor = records.find((record) => record.sensorId === sensorId);
        sensor?.magnitudes.forEach((magnitude) => {
          allMagnitudes.add(magnitude.magnitude);
        });
      });
      setCommonMagnitudes([...allMagnitudes]);
    } else {
      setCommonMagnitudes([]);
      setSelectedMagnitude("");
    }
  }, [selectedSensors]);

  useEffect(() => {
    if (!selectedMagnitude || selectedSensors.length === 0) {
      return;
    }

    const datasets = selectedSensors
      .map((sensorId) => {
        const sensorData = records.find((record) => record.sensorId === sensorId);
        const sensorDataset = {
          label: sensorData ? sensorData.sensorName : sensorId,
          data:
            sensorData?.magnitudes
              .find((m) => m.magnitude === selectedMagnitude)
              ?.records.map((record) => ({
                x: record.data.timestamp * 1000,
                y: record.data.value,
              })) || [],
          borderColor: "black",
          backgroundColor: "rgba(0, 0, 0, 0)",
          type: "line",
        };
        return sensorDataset;
      })
      .filter(Boolean);

    setChartData({ datasets });
  }, [records, selectedMagnitude]);

  const handleChangeSensors = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      if (selectedSensors.length === records.length) {
        setSelectedSensors([]);
        setChartData({ labels: [], datasets: [] });
      } else {
        const allSensorIds = records.map((record) => record.sensorId);
        setSelectedSensors(allSensorIds);
      }
    } else {
      setSelectedSensors(value);
      if (value.length === 0) {
        setChartData({ labels: [], datasets: [] });
      }
    }
  };

  const handleChangeSelectedMagnitude = (event) => {
    setSelectedMagnitude(event.target.value);
  };

  const handleOpenVerify = () => {
    setOpenVerify(true);
    window.history.pushState(null, "");
  };

  const handleView = (record) => {
    setOpenView(true);
    setRecord(record);
    window.history.pushState(null, "");
  };

  const checkVerification = async (record) => {
    try {
      setVerificationLoading(true);

      let verification;

      if (user) {
        verification = await verifySignature(user.uid, record, record.type);
      } else {
        verification = await verifySignature(false, record, record.type);
      }

      setVerification(verification);

      handleOpenVerify();
    } catch (error) {
      console.error("Error in checkVerification:", error.message);
    } finally {
      setVerificationLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Grid item container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <Grid container spacing={5}>
            <Grid item container xs={12} spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker label={t("start_date")} value={startDate} onChange={(date) => setStartDate(date)} />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker label={t("end_date")} value={endDate} onChange={(date) => setEndDate(date)} />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button variant="outlined" onClick={handleSearch} fullWidth startIcon={<SearchIcon />}>
                      {t("search")}
                    </Button>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button variant="outlined" onClick={handleRealTime} fullWidth startIcon={<AutorenewIcon />}>
                      {t("realtime")}
                    </Button>
                  </Grid>
                </Grid>

                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                      <InputLabel id="source_name">{t("source_name")}</InputLabel>
                      <Select
                        labelId={t("source_name")}
                        id="source_name"
                        multiple
                        value={selectedSensors}
                        onChange={handleChangeSensors}
                        input={<OutlinedInput label={t("source_name")} />}
                        renderValue={(selected) =>
                          selected
                            .map((sensorId) => {
                              const sensor = records.find((record) => record.sensorId === sensorId);
                              return sensor ? sensor.sensorName : sensorId;
                            })
                            .join(", ")
                        }
                      >
                        {records
                          .sort((a, b) => a.sensorName.localeCompare(b.sensorName))
                          .map((sensor) => (
                            <MenuItem key={sensor.sensorId} value={sensor.sensorId}>
                              <Checkbox checked={selectedSensors.indexOf(sensor.sensorId) > -1} />
                              <ListItemText primary={sensor.sensorName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <FormControl fullWidth>
                      <InputLabel id="magnitude">{t("magnitude")}</InputLabel>
                      <Select labelId={t("magnitude")} id="magnitude" value={selectedMagnitude} onChange={handleChangeSelectedMagnitude} input={<OutlinedInput label={t("magnitude")} />}>
                        {commonMagnitudes.map((magnitude) => (
                          <MenuItem key={magnitude} value={magnitude}>
                            {magnitude}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container xs={12} justifyContent="flex-end">
                <Button variant="outlined" startIcon={<FileDownloadIcon />} onClick={() => setOpenCsvModal(true)}>
                  {t("export")}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <MpsChart chartData={chartData} />
              </Grid>
            </Grid>

            {selectedSensors && selectedSensors.length !== 0 && (
              <Grid item xs={12}>
                <MpsTable
                  records={records}
                  selectedSensors={selectedSensors}
                  checkVerification={checkVerification}
                  handleView={handleView}
                  page={page}
                  setPage={setPage}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              </Grid>
            )}
          </Grid>

          <MpsExportCSV tag={tag.id} open={openCsvModal} handleClose={() => setOpenCsvModal(false)} isTagGroupMember={isTagGroupMember} />
          {record && <DataDialog data={{ ...record, databoxName: tag.name || "N/A" }} open={openView} onClose={() => setOpenView(false)} />}
          {verificationLoading && <LoadingDialog open={verificationLoading} />}
          {verification && <DatapointIntegrityInspector verification={verification} open={openVerify} setOpen={setOpenVerify} />}
        </>
      )}
    </>
  );
};

MpsViewer.propTypes = {
  tag: PropTypes.object.isRequired,
  isTagGroupMember: PropTypes.bool.isRequired,
};

export default MpsViewer;
