// react
import React from "react";

// @mui
import { Grid, Button, Typography, Divider } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

// components
import MetadataField from "./MetadataField";
import SoggettoForm from "./SoggettoForm";

// MetadataSchema
import { metadataSchemaUpload, metadataSections } from "./metadataSchema";

// A ---------------------------------------------------------------------- M

const CDNFileFormUpload = ({ uploadProperties, setUploadProperties, handleChangeUploadProperties, handleClickOpen }) => {
  const handleAddSoggetto = () => {
    setUploadProperties({
      ...uploadProperties,
      soggetti: [
        ...uploadProperties.soggetti,
        {
          ruolo: "",
          tipoSoggetto: "",
          cognome: "",
          nome: "",
          codiceFiscale: "",
          partitaIva: "",
          indirizziDigitaliDiRiferimento: "",
          denominazioneOrganizzazione: "",
          denominazioneUfficio: "",
          denominazioneAmministrazioneCodiceIPA: "",
          denominazioneAmministrazioneAOOCodiceIPAAOO: "",
          denominazioneAmministrazioneUORCodiceIPAUOR: "",
          denominazioneAmministrazione: "",
          denominazioneSistema: "",
        },
      ],
    });
  };

  const handleSoggettoChange = (index, field, value) => {
    const updatedSoggetti = uploadProperties.soggetti.map((soggetto, i) => (i === index ? { ...soggetto, [field]: value } : soggetto));
    setUploadProperties({ ...uploadProperties, soggetti: updatedSoggetti });
  };

  const handleRemoveSoggetto = (index) => {
    const updatedSoggetti = uploadProperties.soggetti.filter((_, i) => i !== index);
    setUploadProperties({ ...uploadProperties, soggetti: updatedSoggetti });
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item container spacing={3}>
          {metadataSections.map((section) =>
            section.title === "Soggetti" ? (
              <Grid item container spacing={3} key={section.title}>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">{section.title}</Typography>
                  <Divider />
                </Grid>
                {uploadProperties.soggetti.map((soggetto, index) => (
                  <SoggettoForm
                    key={index}
                    soggetto={soggetto}
                    index={index}
                    soggettoFields={section.fields}
                    handleSoggettoChange={handleSoggettoChange}
                    handleRemoveSoggetto={handleRemoveSoggetto}
                    handleClickOpen={handleClickOpen}
                  />
                ))}

                <Grid item xs={12}>
                  <Button variant="outlined" onClick={handleAddSoggetto} startIcon={<AddIcon />}>
                    Soggetto
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <React.Fragment key={section.title}>
                <Grid item xs={12}>
                  <Typography fontWeight="bold">{section.title}</Typography>
                  <Divider />
                </Grid>
                {section.fields.map((fieldKey) => (
                  <MetadataField
                    key={fieldKey}
                    fieldKey={fieldKey}
                    metadata={metadataSchemaUpload}
                    documentProperties={uploadProperties}
                    onChange={handleChangeUploadProperties}
                    handleClickOpen={handleClickOpen}
                  />
                ))}
              </React.Fragment>
            )
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CDNFileFormUpload;
