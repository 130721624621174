// react
import React, { useState, useEffect, useCallback } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";

// components
import DataBoxCreate from "ui-components/DataboxManagement/DataBoxCreate";
import DataBoxesTable from "ui-components/DataboxManagement/DataBoxesTable";
import { DataboxCreationSuccessfulSnackbar } from "ui-components/ORFeedbacks";

// firebase
import { db } from "config/firebase";
import { doc, collection, onSnapshot, query, where } from "firebase/firestore";

// UserOperations
import fetchSeenTags from "UserOperations/fetchSeenTags";

// TagOperations
import getTagInfo from "TagOperations/getTagInfo";

// A ---------------------------------------------------------------------- M

const MyDataboxes = () => {
  const { user, canCreateDatabox, conservSostL1 } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [seentags, setSeenTags] = useState([]);
  const [openGen, setOpenGen] = useState(false);
  const [openDataboxCreationSuccessful, setOpenDataboxCreationSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getSeenTagsWithNames = useCallback(async (userID) => {
    try {
      setIsLoading(true);
      const seentags = await fetchSeenTags(userID);
      const seentagsWithNames = await Promise.all(
        seentags.map(async (tag) => {
          const tagInfo = await getTagInfo(tag.id);
          return { ...tag, name: tagInfo?.name || "N/A" };
        })
      );
      seentagsWithNames.sort((tag1, tag2) => tag2.firstseen - tag1.firstseen);
      setSeenTags(seentagsWithNames);
    } catch (error) {
      console.error("Error in getSeenTagsWithNames:", error.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user?.uid) {
      getSeenTagsWithNames(user.uid);

      const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
      const userdataDocRef = doc(db, "userdata", user.uid);
      const seentagsCollectionRef = collection(userdataDocRef, "seentags");

      const newAdditionsQueryTags = query(seentagsCollectionRef, where("firstseen", ">=", currentTimestampInSeconds.toString()));
      const unsubscribeTags = onSnapshot(newAdditionsQueryTags, async (snapshot) => {
        const newTags = await Promise.all(
          snapshot.docChanges().map(async (change) => {
            if (change.type === "added") {
              const newTagID = change.doc.id;
              const newTagData = change.doc.data();
              const tagInfo = await getTagInfo(newTagID);
              return { ...newTagData, name: tagInfo?.name || "N/A", id: newTagID };
            }
            return null;
          })
        );

        const filteredNewTags = newTags.filter((tag) => tag !== null);
        if (filteredNewTags.length > 0) {
          setSeenTags((prevTags) => [...filteredNewTags, ...prevTags].sort((tag1, tag2) => tag2.firstseen - tag1.firstseen));
        }
      });

      return () => {
        unsubscribeTags();
      };
    }
  }, [user, getSeenTagsWithNames]);

  const openTagGen = () => {
    setOpenGen(true);
    window.history.pushState(null, "");
  };

  const handleOpenDataboxCreationSuccessful = () => {
    setOpenDataboxCreationSuccessful(true);
    window.history.pushState(null, "");
  };

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <Grid item container justifyContent="center" mt="30%">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {seentags && <DataBoxesTable tags={seentags} handleOpen={openTagGen} />}

          {(canCreateDatabox || conservSostL1) && (
            <>
              <DataBoxCreate open={openGen} setOpen={setOpenGen} handleSuccessful={handleOpenDataboxCreationSuccessful} />
              <DataboxCreationSuccessfulSnackbar open={openDataboxCreationSuccessful} setOpen={setOpenDataboxCreationSuccessful} />
            </>
          )}

          {isMobile && (canCreateDatabox || conservSostL1) && (
            <Fab color="primary" aria-label="add-databox" sx={{ position: "fixed", bottom: 120, right: 16 }} onClick={openTagGen}>
              <AddIcon />
            </Fab>
          )}
        </>
      )}
    </Grid>
  );
};

export default MyDataboxes;
