// react
import React, { useState, useEffect } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// react-pdf
import { Document, Page, pdfjs } from "react-pdf";

// react-xml-viewer
import XMLViewer from "react-xml-viewer";

// @mui
import { useMediaQuery, Box, Grid, SwipeableDrawer, Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography, Divider, Tooltip } from "@mui/material";
import {
  CloseOutlined as CloseOutlinedIcon,
  KeyboardArrowLeftOutlined as KeyboardArrowLeftOutlinedIcon,
  KeyboardArrowRightOutlined as KeyboardArrowRightOutlinedIcon,
  FileUpload as FileUploadIcon,
  FileOpen as FileOpenIcon,
  Add as AddIcon,
} from "@mui/icons-material";

// components
import CDNFileForm from "./CDNFileForm";
import CDNFileFormUpload from "./CDNFileFormUpload";
import AttributeDetailsDialog from "./AttributeDetailsDialog";
import CDNFileUploadReceipt from "./CDNFileUploadReceipt";
import { Puller } from "ui-components/Puller";
import { LoadingDialog } from "ui-components/LoadingComponent";
import { NewCertConfirm, InvalidFile } from "ui-components/ORFeedbacks";

// utils
import fileToUint8Array from "utils/fileToUint8Array";

// generator
import genCDNFileRecord from "generator/CDNFileGenerator/genCDNFileRecord";

// SafeTwin
import { calculateSHA256, toHex, genRndString } from "SafeTwin/crypto/cryptolibsodium";

// firebase
import { db } from "config/firebase";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

// initialUploadProperties
import { initialUploadProperties } from "./initialUploadProperties";

// initialDocumentProperties
import { initialDocumentProperties } from "./initialDocumentProperties";

// A ---------------------------------------------------------------------- M

const MAX_FILE_SIZE = 25 * 1024 * 1024;

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CDNFileGenerator = ({ tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError }) => {
  const { t } = useTranslation();
  const { user } = UserAuth();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [fileType, setFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [xmlContent, setXmlContent] = useState(null);
  const [textContent, setTextContent] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidFile, setIsInvalidFile] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState(null);
  const [uploadID, setUploadID] = useState(null);
  const [receiptDialogOpen, setReceiptDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [uploadProperties, setUploadProperties] = useState(initialUploadProperties);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [primaryDocumentIndex, setPrimaryDocumentIndex] = useState(null);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    if (!uploadID) return;

    const receiptRef = doc(db, "uploadReceipts", uploadID);
    const unsubscribe = onSnapshot(receiptRef, (doc) => {
      if (doc.exists()) {
        const receiptData = doc.data();
        setUploadReceipt(receiptData);
        setReceiptDialogOpen(true);
      }
    });

    return () => unsubscribe();
  }, [uploadID]);

  useEffect(() => {
    if (tag?.tipologiaDocumentale) {
      setUploadProperties((prevProperties) => ({
        ...prevProperties,
        tipologiaDocumentale: tag.tipologiaDocumentale,
      }));
    }
  }, [tag]);

  const handleAddNewFile = () => {
    setFiles([...files, {}]);
  };

  const handleFileChange = async (target, index) => {
    if (target?.files?.length > 0) {
      const file = target.files[0];

      if (file.size > MAX_FILE_SIZE) {
        console.error("File size exceeds 25MB limit.");
        setErrorMessage("File size exceeds the 25MB limit.");
        setIsInvalidFile(true);
        resetFileInput();
        return;
      }

      const validExtensions = ["pdf", "xml", "txt", "csv", "json", "png", "jpg", "jpeg", "webm"];
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const mimeType = file.type;

      if (
        validExtensions.includes(fileExtension) &&
        (mimeType.includes("application/pdf") ||
          mimeType.includes("text/xml") ||
          mimeType.includes("application/xml") ||
          mimeType.includes("text/plain") ||
          mimeType.includes("text/csv") ||
          mimeType.includes("application/json") ||
          mimeType.includes("image/png") ||
          mimeType.includes("image/jpg") ||
          mimeType.includes("image/jpeg") ||
          mimeType.includes("video/webm") ||
          mimeType.includes("video/x-matroska") ||
          mimeType.includes("video/mp4"))
      ) {
        const fileByteArray = await fileToUint8Array(file);
        const fileData = {
          source: fileByteArray,
          blobURL: URL.createObjectURL(file),
          fileName: file.name,
          fileType: file.type,
          documentProperties: {
            ...initialDocumentProperties,
            nomeDocumento: file.name,
            hash: toHex(calculateSHA256(fileByteArray)),
            algoritmo: "SHA-256",
            formatoDocumento: file.type,
          },
        };

        const updatedFiles = [...files];
        updatedFiles[index] = fileData;
        setFiles(updatedFiles);
      } else {
        console.error(`Invalid file format: ${fileExtension}. Available format: "pdf", "xml", "txt", "csv", "json", "png", "jpg", "jpeg", "webm", "mp4".`);
        setErrorMessage(`Invalid file format: ${fileExtension}. Available format: "pdf", "xml", "txt", "csv", "json", "png", "jpg", "jpeg", "webm", "mp4.`);
        setIsInvalidFile(true);
        resetFileInput();
      }
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleGenerate = async () => {
    setIsLoading(true);

    const uploadID = genRndString(32);
    console.log("uploadID:", uploadID);
    setUploadID(uploadID);

    try {
      const statusRef = doc(db, "uploadStatuses", uploadID);

      await setDoc(statusRef, {
        createdBy: user.uid,
        createdByEmail: user.email,
        totalCount: files.length,
        validCount: 0,
        invalidCount: 0,
        status: "processing",
      });

      const documentiArray = files.map((file) => file.documentProperties);
      const primaryDocument = primaryDocumentIndex !== null ? files[primaryDocumentIndex].documentProperties.idDocumento : null;

      const updatedUploadProperties = {
        ...uploadProperties,
        documenti: documentiArray,
        documentoPrincipale: primaryDocument,
      };

      const uploadedFiles = [];

      for (const file of files) {
        const { source, fileName, fileType } = file;

        try {
          const storagePath = `${uploadID}/${fileName}`;
          const storageRef = ref(getStorage(), storagePath);

          // prettier-ignore
          const metadata = {
            contentType: fileType,
            customMetadata: {
              'tagID': tag.id,
              'isPublic': "false",
              'requiresValidation': "true",
            },
          };

          console.log("Uploading file...");
          await uploadBytes(storageRef, source, metadata);
          console.log("File uploaded successfully!");

          const downloadURL = await getDownloadURL(storageRef);

          uploadedFiles.push({
            attachment: downloadURL,
            fileName,
            fileType,
          });
        } catch (uploadError) {
          console.error(`Error uploading file ${fileName}:`, uploadError.message);
          throw uploadError;
        }
      }

      const dataBody = {
        uploadID,
        uploadedFiles,
        uploadProperties: updatedUploadProperties,
      };

      const result = await genCDNFileRecord(user.uid, dataBody, tag.id);
      console.log(result);

      if (result.success) {
        handleOpenCertificationSuccessful();
        if (types && !types.includes("CDNFile")) {
          setTypes([...types, "CDNFile"]);
        }
      } else {
        handleOpenCertificationError();
      }
    } catch (error) {
      console.error("Error during multi-upload:", error.message);
      handleOpenCertificationError();
    } finally {
      setIsLoading(false);
      handleReset();
    }
  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("new-upload-home-button");
    if (fileInput) {
      fileInput.value = null;
    }
  };

  const handleReset = () => {
    setFiles([]);
    setUploadProperties((prevProperties) => ({
      ...initialUploadProperties,
      tipologiaDocumentale: prevProperties.tipologiaDocumentale,
    }));
    setOpen(false);
    setConfirm(false);
    setPrimaryDocumentIndex(null);
  };

  const handleFileClick = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);

      if (!response.ok) {
        throw new Error(`Failed to fetch file. HTTP status: ${response.status}`);
      }

      const blob = await response.blob();
      const fileType = blob.type;
      setFileType(fileType);

      if (fileType === "application/pdf") {
        setSelectedFile(fileUrl);
      } else if (fileType === "text/xml" || fileType === "application/xml") {
        const xmlText = await blob.text();
        setXmlContent(xmlText);
        setSelectedFile(fileUrl);
      } else if (fileType === "text/plain" || fileType === "text/csv") {
        const textContent = await blob.text();
        setTextContent(textContent);
        setSelectedFile(fileUrl);
      } else if (fileType === "application/json") {
        const jsonText = await blob.text();
        try {
          const parsedJson = JSON.parse(jsonText);
          setTextContent(parsedJson);
        } catch (error) {
          throw new Error("Invalid JSON format");
        }
        setSelectedFile(fileUrl);
      } else if (fileType === "image/png" || fileType === "image/jpg" || fileType === "image/jpeg") {
        setSelectedFile(fileUrl);
      } else if (fileType === "video/webm" || fileType === "video/x-matroska" || fileType === "video/mp4") {
        setSelectedFile(fileUrl);
      } else {
        throw new Error("Unsupported file type");
      }
    } catch (error) {
      console.error("Error fetching and parsing file:", error);
    }
  };

  const handleDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseDialog = () => {
    setSelectedFile(null);
    setXmlContent("");
    setTextContent("");
    setCurrentPage(1);
  };

  const handleClickOpen = (content) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setDialogContent("");
  };

  const handleChangeDocumentProperties = (fileIndex, fieldKey, value) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles[fileIndex] = {
        ...updatedFiles[fileIndex],
        documentProperties: {
          ...updatedFiles[fileIndex].documentProperties,
          [fieldKey]: value,
        },
      };
      return updatedFiles;
    });
  };

  const handleChangeUploadProperties = (fieldKey, value) => {
    setUploadProperties({ ...uploadProperties, [fieldKey]: value });
  };

  const handleSetPrimaryDocument = (index) => {
    setPrimaryDocumentIndex(index);
  };

  const handleResetUploadReceipt = () => {
    setUploadID(null);
    setUploadReceipt(null);
    setReceiptDialogOpen(false);
  };

  const renderFileDialog = () => {
    if (!selectedFile) {
      return null;
    }

    switch (fileType) {
      case "application/pdf":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Document file={selectedFile} onLoadSuccess={handleDocumentLoadSuccess}>
              <Page pageNumber={currentPage} width={isMobile ? 300 : 600} renderAnnotationLayer={false} renderTextLayer={false} />
            </Document>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <IconButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} aria-label="previous page">
                <KeyboardArrowLeftOutlinedIcon />
              </IconButton>
              <Typography>{`${currentPage} / ${numPages}`}</Typography>
              <IconButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === numPages} aria-label="next page">
                <KeyboardArrowRightOutlinedIcon />
              </IconButton>
            </Box>
          </Dialog>
        );

      case "text/xml":
      case "application/xml":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <XMLViewer xml={xmlContent} />
            </Box>
          </Dialog>
        );

      case "text/plain":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <Typography>{textContent}</Typography>
            </Box>
          </Dialog>
        );

      case "text/csv":
        try {
          const rows = textContent.split("\n").map((row) => row.split(","));
          return (
            <Dialog
              open
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <table>
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                          <td key={cellIndex}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
            </Dialog>
          );
        } catch {
          return (
            <Dialog
              open={Boolean(selectedFile)}
              onClose={handleCloseDialog}
              fullWidth
              maxWidth="sm"
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "15px",
                  padding: 1,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Tooltip placement="top" title={t("close")}>
                  <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                    <CloseOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ p: 3 }}>
                <Typography>{textContent}</Typography>
              </Box>
            </Dialog>
          );
        }

      case "application/json":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 3 }}>
              <pre>{JSON.stringify(textContent, null, 2)}</pre>
            </Box>
          </Dialog>
        );

      case "image/png":
      case "image/jpg":
      case "image/jpeg":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Tooltip placement="top" title={t("close")}>
              <IconButton
                color="error"
                sx={{
                  position: "absolute",
                  top: "0%",
                  right: "0%",
                  zIndex: 1,
                }}
                onClick={handleCloseDialog}
                aria-label="close"
              >
                <CloseOutlinedIcon />
              </IconButton>
            </Tooltip>
            <img src={selectedFile} alt="img" />
          </Dialog>
        );

      case "video/webm":
      case "video/x-matroska":
      case "video/mp4":
        return (
          <Dialog
            open={Boolean(selectedFile)}
            onClose={handleCloseDialog}
            fullWidth
            maxWidth="sm"
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "15px",
                padding: 0,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Tooltip placement="top" title={t("close")}>
                <IconButton color="error" onClick={handleCloseDialog} aria-label="close">
                  <CloseOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box sx={{ p: 2 }}>
              <video
                src={selectedFile}
                controls
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              >
                Your browser does not support the video tag.
              </video>
            </Box>
          </Dialog>
        );

      default:
        return null;
    }
  };

  return isLoading ? (
    <LoadingDialog open={isLoading} />
  ) : (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "80%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight="bold" textAlign="center" mt={2} mb={3}>
              {t("upload")}
            </Typography>
            <>
              {files.map((file, index) => (
                <Box
                  key={index}
                  mb={4}
                  p={3}
                  sx={{
                    border: "1px solid #ddd",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#fafafa",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                        {`${t("document")} ${index + 1}`}
                      </Typography>
                      <Divider sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={2}>
                        <Typography>{t("file")} *</Typography>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        <input
                          id={`file-upload-${index}`}
                          type="file"
                          accept="application/pdf, text/xml, application/xml, text/plain, text/csv, application/json, image/png, image/jpg, image/jpeg, video/webm, video/x-matroska, video/mp4"
                          style={{ display: "none" }}
                          onChange={(e) => handleFileChange(e.target, index)}
                        />
                        <label htmlFor={`file-upload-${index}`}>
                          <Button startIcon={<FileUploadIcon />} variant="contained" component="span" sx={{ width: isMobile ? "100%" : "30%" }}>
                            {t("select_file")}
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={2}>
                        <Typography>{t("file_name")}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        {file.fileName ? (
                          <Button startIcon={<FileOpenIcon />} variant="contained" onClick={() => handleFileClick(file.blobURL)}>
                            {file.fileName}
                          </Button>
                        ) : (
                          <Typography>{t("no_file_selected")}</Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CDNFileForm
                        source={file.source}
                        documentProperties={file.documentProperties}
                        onChange={(fieldKey, value) => handleChangeDocumentProperties(index, fieldKey, value)}
                        handleClickOpen={handleClickOpen}
                        isPrimary={primaryDocumentIndex === index}
                        onSetPrimary={() => handleSetPrimaryDocument(index)}
                      />
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                      <Button variant="contained" color="error" onClick={() => handleRemoveFile(index)} sx={{ mt: 2 }}>
                        {t("remove")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button variant="outlined" onClick={handleAddNewFile} startIcon={<AddIcon />}>
                {t("document")}
              </Button>
              <Divider sx={{ my: 4 }} />
              <CDNFileFormUpload
                uploadProperties={uploadProperties}
                setUploadProperties={setUploadProperties}
                handleChangeUploadProperties={handleChangeUploadProperties}
                handleClickOpen={handleClickOpen}
              />
              <Grid container spacing={1} mt="5%">
                <Grid item xs={12}>
                  <Button variant="contained" disabled={isLoading || files.length === 0} onClick={() => setConfirm(true)}>
                    {t("upload")}
                  </Button>
                </Grid>
              </Grid>
            </>
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleReset}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 0.5,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("upload")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleReset} color="error" edge="end">
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            {files.map((file, index) => (
              <Box
                key={index}
                mb={4}
                p={3}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  backgroundColor: "#fafafa",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
                      {`${t("document")} ${index + 1}`}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                  </Grid>
                  <Grid item container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file")} *</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      <input
                        id={`file-upload-${index}`}
                        type="file"
                        accept="application/pdf, text/xml, application/xml, text/plain, text/csv, application/json, image/png, image/jpg, image/jpeg, video/webm, video/x-matroska, video/mp4"
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(e.target, index)}
                      />
                      <label htmlFor={`file-upload-${index}`}>
                        <Button startIcon={<FileUploadIcon />} variant="contained" component="span" sx={{ width: isMobile ? "100%" : "30%" }}>
                          {t("select_file")}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography>{t("file_name")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                      {file.fileName ? (
                        <Button startIcon={<FileOpenIcon />} variant="contained" onClick={() => handleFileClick(file.blobURL)}>
                          {file.fileName}
                        </Button>
                      ) : (
                        <Typography>{t("no_file_selected")}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <CDNFileForm
                      source={file.source}
                      documentProperties={file.documentProperties}
                      onChange={(fieldKey, value) => handleChangeDocumentProperties(index, fieldKey, value)}
                      handleClickOpen={handleClickOpen}
                      isPrimary={primaryDocumentIndex === index}
                      onSetPrimary={() => handleSetPrimaryDocument(index)}
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-end">
                    <Button variant="contained" color="error" onClick={() => handleRemoveFile(index)} sx={{ mt: 2 }}>
                      {t("remove")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button variant="outlined" onClick={handleAddNewFile} startIcon={<AddIcon />}>
              {t("document")}
            </Button>
            <Divider sx={{ my: 4 }} />
            <CDNFileFormUpload
              uploadProperties={uploadProperties}
              setUploadProperties={setUploadProperties}
              handleChangeUploadProperties={handleChangeUploadProperties}
              handleClickOpen={handleClickOpen}
            />
          </DialogContent>
          <DialogActions>
            <Button color="error" onClick={handleReset}>
              {t("cancel")}
            </Button>
            <Button variant="contained" disabled={isLoading || files.length === 0} onClick={() => setConfirm(true)}>
              {t("upload")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {confirm && <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />}

      {isInvalidFile && <InvalidFile open={isInvalidFile} setOpen={setIsInvalidFile} message={errorMessage} />}

      <AttributeDetailsDialog open={openDialog} handleClose={handleDialogClose} content={dialogContent} />

      {renderFileDialog()}

      {uploadReceipt && <CDNFileUploadReceipt uploadReceipt={uploadReceipt} receiptDialogOpen={receiptDialogOpen} handleResetUploadReceipt={handleResetUploadReceipt} />}
    </>
  );
};

export default CDNFileGenerator;
