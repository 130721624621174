// firebase
import { db } from "config/firebase";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

/**
 * Fetches alarm events for a given tagID with an optional limit.
 *
 * @param {string} tagID - The ID of the tag to fetch alarm events for.
 * @param {number} maxRecords - The maximum number of alarm events to fetch. Defaults to 10.
 * @returns {Promise<Array>} A promise that resolves to an array of alarm events.
 */
const fetchAlarmEvents = async (tagID, maxRecords = 10) => {
  try {
    const alarmEventsRef = collection(db, "tagsdata", tagID, "alarm_events");
    const q = query(alarmEventsRef, orderBy("timestamp", "desc"), limit(maxRecords));
    const querySnapshot = await getDocs(q);

    const alarmEvents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return alarmEvents;
  } catch (error) {
    console.error("Error fetching alarm events:", error.message);
    throw error;
  }
};

export default fetchAlarmEvents;
