// firebase
import { db } from "config/firebase";
import { doc, collection, setDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// A ---------------------------------------------------------------------- M

const createDataKey = async (datakey, name, uuid) => {
  try {
    const keyID = doc(collection(db, "keysecrets")).id;
    const createdOn = unixTimestampInSeconds();

    const keySecretsDocRef = doc(db, "keysecrets", keyID);
    await setDoc(keySecretsDocRef, {
      datakey,
      owner_uuid: uuid,
    });

    const keyDataDocRef = doc(db, "keydata", keyID);
    await setDoc(keyDataDocRef, {
      name,
      owner_uuid: uuid,
      created_on: createdOn,
    });

    console.log(`Create datakey with ID: ${keyID}`);
    return keyID;
  } catch (error) {
    console.error(`Error in createDataKey for user ${uuid} and key name ${name}:`, error.message);
    return null;
  }
};

export default createDataKey;
