// firebase
import { db } from "config/firebase";
import { doc, deleteDoc } from "firebase/firestore";

// utils
import unixTimestampInSeconds from "utils/unixTimestampInSeconds";

// eventLogger
import logEvent from "eventLogger/logEvent";

// A ---------------------------------------------------------------------- M

const removeAuthorizedKey = async (tagID, keyID, userID) => {
  const timestamp = unixTimestampInSeconds();

  try {
    const authorizedKeyRef = doc(db, "tagsdata", tagID, "authorized_keys", keyID);
    await deleteDoc(authorizedKeyRef);
    await logEvent(tagID, "event_remove_datakey_success", timestamp, `${keyID} removed from ${tagID} successfully.`, null, userID, null, "success");
  } catch (error) {
    console.error(`Error in removeAuthorizedKey for tagID ${tagID} and keyID ${keyID}:`, error.message);
    await logEvent(tagID, "event_remove_datakey_error", timestamp, `${keyID} removed from ${tagID} failed.`, null, userID, null, "error");
  }
};

export default removeAuthorizedKey;
