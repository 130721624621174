// firebase
import { getStorage, ref, getBytes } from "firebase/storage";

// A ---------------------------------------------------------------------- M

const getFile = async (url) => {
  try {
    const storage = getStorage();

    const fileRef = ref(storage, url);
    const fileArrayBuffer = await getBytes(fileRef);
    const fileByteArray = new Uint8Array(fileArrayBuffer);

    return fileByteArray;
  } catch (error) {
    console.error("Error in getFile:", error.message);
    return null;
  }
};

export default getFile;
