// react
import React, { useEffect, useState } from "react";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddIcon from "@mui/icons-material/Add";

// components
import { Puller } from "ui-components/Puller";
import { LoadingComponent } from "ui-components/LoadingComponent";

// DatakeyOperations
import createDataKey from "DatakeyOperations/createDataKey";

// A ---------------------------------------------------------------------- M

const NewDataKey = ({ dataKey, open, setOpen, handleClose, setOpenDataKeySnackbarSuccessful, setOpenDataKeySnackbarError }) => {
  const { user } = UserAuth();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  const handleCreate = async () => {
    setLoading(true);
    const createdDoc = await createDataKey(dataKey, name, user.uid);
    if (createdDoc) {
      setOpenDataKeySnackbarSuccessful(true);
    } else {
      setOpenDataKeySnackbarError(true);
    }
    setLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setName("");
    handleClose();
  };

  const DataKeyForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField margin="dense" id="value" label={t("value")} type="text" fullWidth variant="outlined" value={dataKey} />
      </Grid>
      <Grid item xs={12}>
        <TextField margin="dense" id="name" label={t("name")} type="text" fullWidth variant="outlined" value={name} onChange={(e) => setName(e.target.value)} />
      </Grid>
    </Grid>
  );

  return isMobile ? (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleReset}
      onOpen={() => setOpen(true)}
      sx={{
        "& .MuiDrawer-paper": {
          width: "100%",
          height: "45%",
          borderTopLeftRadius: "4%",
          borderTopRightRadius: "4%",
        },
      }}
      data-testid="datakey-create-modal"
    >
      <Puller />
      <Box sx={{ p: "5%" }}>
        <Grid container alignItems="center" justifyContent="center" mt="5%" mb="10%">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              {t("new_datakey")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {DataKeyForm}
          <Grid container mt="3%">
            <Grid item xs={12}>
              <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={handleCreate} disabled={!dataKey || !name}>
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </SwipeableDrawer>
  ) : (
    <Dialog open={open} onClose={handleReset} maxWidth="md" fullWidth data-testid="datakey-create-modal">
      <DialogTitle>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" fontWeight="bold">
              {t("new_datakey")}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={handleReset} edge="end" sx={{ color: "red" }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {loading ? (
        <LoadingComponent />
      ) : (
        <DialogContent dividers>
          {DataKeyForm}
          <Grid item container xs={12} mt="3%">
            <Grid item xs={12}>
              <Button fullWidth variant="contained" startIcon={<AddIcon />} onClick={handleCreate} disabled={!dataKey || !name}>
                {t("create")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default NewDataKey;
