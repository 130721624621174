// firebase
import { db } from "config/firebase";
import { doc, updateDoc } from "firebase/firestore";

// A ---------------------------------------------------------------------- M

const editClientPolicy = async (client, tipologiaDocumentale, condition) => {
  try {
    const { id: clientID } = client;

    const docRef = doc(db, "clientsdata", clientID, "agreements", tipologiaDocumentale);

    await updateDoc(docRef, { condition });

    console.log(`Client policy for client ID ${clientID} and document type ${tipologiaDocumentale} updated successfully.`);
  } catch (error) {
    console.error(`Error updating client policy for client ID ${client.id} and document type ${tipologiaDocumentale}:`, error.message);
  }
};

export default editClientPolicy;
