// react
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// contexts
import { UserAuth } from "context/AuthContext";

// react-i18next
import { useTranslation } from "react-i18next";

// @mui
import {
  useMediaQuery,
  Box,
  Grid,
  MenuItem,
  SwipeableDrawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Select,
  Switch,
} from "@mui/material";
import { CloseOutlined as CloseOutlinedIcon } from "@mui/icons-material";

// components
import { LinearLoadingComponent } from "ui-components/LoadingComponent";
import { Puller } from "ui-components/Puller";
import { NewCertConfirm, NewRequestConfirm } from "ui-components/ORFeedbacks";

// SafeTwin
import { decryptSeedByPasswordHash4Ed25519 } from "SafeTwin/crypto/cryptoseed";

// InforGenerator
import genInfoRecord from "generator/InfoGenerator/genInfoRecord";

// TagOperations
import sendCertificationRequest from "TagOperations/sendCertificationRequest";
import completeCertificationRequest from "TagOperations/completeCertificationRequest";

// A ---------------------------------------------------------------------- M

const InfoGenerator = ({ tags, tag, types, setTypes, open, setOpen, handleOpenCertificationSuccessful, handleOpenCertificationError, request, setRequest }) => {
  const { user } = UserAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [keypair, setKeyPair] = useState(null);
  const [text, setText] = useState("");
  const [selectedTag, setSelectedTag] = useState("");
  const [tagSelectionOpen, setTagSelectionOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [approval, setApproval] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handlePopstate = () => {
      if (open) {
        handleReset();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [open]);

  useEffect(() => {
    const decrypt = () => {
      if (open) {
        const keypair = decryptSeedByPasswordHash4Ed25519(user.reloadUserInfo.passwordHash);
        setKeyPair(keypair);
      }
    };

    decrypt();
  }, [open]);

  useEffect(() => {
    if (request) {
      setText(request.data.text);
      setIsPublic(request.public);
    } else {
      setText("");
      setIsPublic(false);
    }
  }, [request]);

  const handleGenerate = async () => {
    setConfirm(false);
    setIsLoading(true);

    if (keypair) {
      try {
        let result = null;

        const dataBody = {
          text,
        };

        if (tag) {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody, tag);
        } else if (selectedTag) {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody, selectedTag);
        } else {
          result = await genInfoRecord(isPublic, user.uid, keypair, dataBody);
        }

        if (result.success) {
          if (request) {
            await completeCertificationRequest(tag, request.id);
          }

          handleOpenCertificationSuccessful();

          if (types && !types.includes("info")) {
            setTypes([...types, "info"]);
          }
        } else {
          handleOpenCertificationError();
        }
      } catch (error) {
        console.error("Error in handleGenerate:", error.message);
        handleOpenCertificationError();
      } finally {
        setIsLoading(false);
        handleReset();
      }
    } else {
      console.log("No keypair provided.");
    }

    setIsLoading(false);
    handleReset();
  };

  const handleSendApprovalRequest = async () => {
    setApproval(false);
    setIsLoading(true);

    const requestData = { text };

    const requestTag = tag || selectedTag;

    await sendCertificationRequest("info", user.uid, isPublic, requestData, requestTag);

    setIsLoading(false);
    handleReset();
  };

  const handleReset = () => {
    setText("");
    setSelectedTag("");
    setIsPublic(false);

    if (request) {
      setRequest();
    }

    setOpen(false);
  };

  const handleChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleOpen = () => {
    setTagSelectionOpen(true);
    window.history.pushState(null, "");
  };

  const handleClose = () => {
    setTagSelectionOpen(false);
  };

  const InfoReportForm = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField fullWidth label={t("text")} value={text} onChange={(e) => setText(e.target.value)} required disabled={request} />
      </Grid>
      {location.pathname === "/my-certifications" && tags && (
        <Grid item xs={12}>
          <Typography gutterBottom>{t("databox")}</Typography>
          <FormControl fullWidth>
            <Select
              id="selected-tag-info"
              value={selectedTag}
              displayEmpty
              onChange={handleChange}
              open={tagSelectionOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              <MenuItem value="">
                <Typography color="gray">{t("no_databox")}</Typography>
              </MenuItem>
              {tags
                .sort((a, b) => a.nickname.localeCompare(b.nickname))
                .map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    {tag.nickname}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isPublic} onChange={(event) => setIsPublic(event.target.checked)} disabled={request} inputProps={{ "aria-label": "controlled" }} />}
            label={t("mark_as_public")}
          />
        </FormGroup>
      </Grid>
    </Grid>
  );

  return (
    <>
      {isMobile ? (
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={handleReset}
          onOpen={() => setOpen(true)}
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              height: "80%",
              borderTopLeftRadius: "4%",
              borderTopRightRadius: "4%",
            },
          }}
        >
          <Puller />
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight="bold" textAlign="center" mt={2} mb={3}>
              {t("certify_note")}
            </Typography>
            {isLoading ? (
              <LinearLoadingComponent />
            ) : (
              <>
                {InfoReportForm}
                <Grid container spacing={1} mt={5}>
                  <Grid item xs={12}>
                    <Button fullWidth variant="outlined" onClick={handleReset}>
                      {t("cancel")}
                    </Button>
                  </Grid>
                  {!request && (
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        disabled={isLoading || (location.pathname === "/my-certifications" && (!text || !selectedTag)) || (location.pathname !== "/my-certifications" && !text)}
                        onClick={() => setApproval(true)}
                      >
                        {t("send_request")}
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button fullWidth variant="contained" disabled={isLoading || !text} onClick={() => setConfirm(true)}>
                      {t("certify")}
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </SwipeableDrawer>
      ) : (
        <Dialog
          open={open}
          onClose={handleReset}
          maxWidth="md"
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "15px",
              padding: 0.5,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            },
          }}
        >
          <DialogTitle>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  {t("certify_note")}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={handleReset} color="error" edge="end">
                  <CloseOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          {isLoading ? (
            <LinearLoadingComponent />
          ) : (
            <>
              <DialogContent dividers>{InfoReportForm}</DialogContent>
              <DialogActions>
                <Button onClick={handleReset}>{t("cancel")}</Button>
                {!request && (
                  <Button
                    variant="contained"
                    disabled={isLoading || (location.pathname === "/my-certifications" && (!text || !selectedTag)) || (location.pathname !== "/my-certifications" && !text)}
                    onClick={() => setApproval(true)}
                  >
                    {t("send_request")}
                  </Button>
                )}
                <Button variant="contained" disabled={isLoading || !text} onClick={() => setConfirm(true)}>
                  {t("certify")}
                </Button>
              </DialogActions>
            </>
          )}
        </Dialog>
      )}

      <NewCertConfirm confirm={confirm} setConfirm={setConfirm} handleGenerate={handleGenerate} />
      <NewRequestConfirm approval={approval} setApproval={setApproval} handleSendApprovalRequest={handleSendApprovalRequest} />
    </>
  );
};

export default InfoGenerator;
