// RecordOperations
import fetchRecordsByType from "RecordOperations/fetchRecordsByType";

// A ---------------------------------------------------------------------- M

const fetchSensorAndMagnitudeData = async (tagId, isTagGroupMember) => {
  const startTime = new Date(Date.now() - 24 * 60 * 60 * 1000);
  const endTime = new Date();

  const records = await fetchRecordsByType(tagId, "mps", isTagGroupMember, startTime, endTime);

  const groupedRecords = Object.values(
    records.reduce((accumulator, currentRecord) => {
      const sensorId = currentRecord.creator_uuid;
      const sensorName = currentRecord.creator_email || sensorId;
      const magnitude = currentRecord.data.magnitude || currentRecord.data.uom;

      if (sensorId != null) {
        if (!accumulator[sensorId]) {
          accumulator[sensorId] = { sensorId, sensorName, magnitudes: [] };
        }

        const existingMagnitudeGroup = accumulator[sensorId].magnitudes.find((group) => group.magnitude === magnitude);
        if (!existingMagnitudeGroup) {
          accumulator[sensorId].magnitudes.push({
            magnitude,
            records: [currentRecord],
          });
        } else {
          existingMagnitudeGroup.records.push(currentRecord);
        }
      }

      return accumulator;
    }, {})
  );

  groupedRecords.forEach((sensor) => {
    sensor.magnitudes.forEach((magnitudeGroup) => {
      magnitudeGroup.records.sort((a, b) => a.data.timestamp_end - b.data.timestamp_end);
    });
  });

  const sensors = groupedRecords.map((sensor) => ({
    id: sensor.sensorId,
    name: sensor.sensorName,
  }));

  const magnitudes = Array.from(new Set(groupedRecords.flatMap((sensor) => sensor.magnitudes.map((magnitudeGroup) => magnitudeGroup.magnitude))));

  return { sensors, magnitudes };
};

export default fetchSensorAndMagnitudeData
